import React, { useState } from "react";
// import DataGrid from "react-data-grid";
import 'react-data-grid/dist/react-data-grid.css';
import "./styles.css";
import { Delete } from '@material-ui/icons/';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import InfoIcon from '@material-ui/icons/Info';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import ShortTextIcon from '@material-ui/icons/ShortText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SlideshowIcon from '@material-ui/icons/Slideshow';

const { Selectors } = Data;



class DataGridBase extends React.Component {

  columns = this.props.columns

  state = {
    originalRows: this.props.intialRows, //initial rows passed in from downloadfiles
    rows: this.props.intialRows, //clone of originalRows , used for sorting
    filters: {}, //search filters
    enableHEaderFilters: {},
    selectedRows: new Set(),
    sortColumn:null,
    sortDirection:null,
  }

  componentDidUpdate() {
    if (this.props.intialRows !== this.state.originalRows) this.setState({ originalRows: this.props.intialRows, rows: this.props.intialRows })
  }

  componentDidMount()
  {
    //console.log(this.props.sort)
    if(this.props.sort!=null)
    {
      this.setState({sortColumn:this.props.sort.sortColumn,sortDirection:this.props.sort.sortDirection})
    }
  }

  //handles sorting of columns by asc,desc, or default
  handleGridSortOld = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);

    this.setState({ rows, sortColumn, sortDirection });
  };

  handleGridSort = (sortColumn, sortDirection) => {
    this.setState({ sortColumn, sortDirection });
  };

  //#region getters
  getRows = () => {
    return Selectors.getRows(this.state);
  };

  getSize = () => {
    return this.getRows().length;
  };

  rowGetter = (rowIdx) => {
    const rows = this.getRows();
    return rows[rowIdx];
  };

  //#endregion

   unixTimeUTC = new Date().setUTCHours(23, 59, 59, 999);
  //WE SHOULD REVISIT THIS IMPLEMENTATION LATER AS THIS BREAKS THE "REUSABLE" DESIGN
  //used by react-data-grid to assign clickable actions to cell.
  getCellActions = (column, row) => {
    if (column.key === 'Download') { //DOWNLOAD COLUMN
      return [
        {
          icon: <CloudDownloadIcon style={{ width: 100 }} />,
          callback: () => { this.downloadFile(row.FullName, row.FileName) }
        },
      ]; //square bracket is used as a second button layer can be added in 
    }
// edited time limitation of delete button
    else if (column.key === 'Delete') { //DELETE COLUMN
      console.log('row :>> ', row.UploadTime);
      const nextDate0159UTC = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 1, 1, 59)).getTime();
// console.log('nextDate0159UTC :>> ', nextDate0159UTC);
const currentUTCTimeStamp13 = new Date().getTime();
const yesterdayEndOfDayTimestamp = new Date(new Date().setDate(new Date().getDate() - 1)).setUTCHours(23, 59, 59, 999);
// console.log('yesterdayEndOfDayTimestamp :>> ', yesterdayEndOfDayTimestamp);
console.log('cuurentUTCTimeStamp13 :>> ', currentUTCTimeStamp13 );
const testValue = 1690966800000
console.log('testValue :>> ', testValue);
console.log('testValue - row.uploadtime :>> ', testValue - row?.UploadTime);
// if(row.uploadTime < yesterdayEndOfDayTimestamp){
//   console.log('nope :>> ');
//   return [
//     {
//       icon: <Delete style={{ width: 100 }} color="disabled" />,
      
//     },
//   ]}
     if((row?.UploadTime< testValue  )&&( row?.UploadTime> yesterdayEndOfDayTimestamp )&&(currentUTCTimeStamp13< nextDate0159UTC ) ){ 
      return [
        {
          icon: <Delete style={{ width: 100 }} />,
          callback: () => { this.deleteFile(row.FullName, row.Sender) }
        },
      ];}
   
      else{return [
        {
          icon: <Delete style={{ width: 100 }} color="disabled" />,
          
        },
      ]}
      
    }
 //square bracket is used as a second button layer can be added in 
    else if (column.key === 'Information') { //INFO (I) COLUMN
      return [
        {
          icon: <InfoIcon style={{ width: 100 }} />,
          callback: () => { this.handleInfo("Total Downloads: " + row.TotalDownloads) }
        },
      ]; //square bracket is used as a second button layer can be added in 
    }

    else if (column.key === 'UserInfo') { //INFO (I) COLUMN
      return [
        {
          icon: <PermContactCalendarIcon style={{ width: 100 }} />,
          callback: () => { this.handleUserList(row) }
        },
      ]; //square bracket is used as a second button layer can be added in 
    }

    else if (column.key === ('Receiver')) {
      return [
        {
          icon: <PermContactCalendarIcon style={{ width: 80, }} />,
          callback: () => { this.handleRecipient(row.ReceiverArray,row) }      //use new variable to makefriendlist
        },
      ];
    }

    else if (column.key === 'FileType') {   // file icons called here

      switch (row.Category) {
        case 'pdf':
          return [
            {
              icon:
                <div style={{ width: 100 }} >
                  <PictureAsPdfIcon />
                </div>
            }
          ];

        case 'xls':
        case 'xlsx':
          return [
            {
              icon:
                <div style={{ width: 100 }}>
                  <ListAltIcon />
                </div>

            }
          ];

        case 'ppt':
        case 'pptx':
          return [
            {
              icon:
                <div style={{ width: 100 }}>
                  <SlideshowIcon />
                </div>

            }
          ];

        case 'doc':
        case 'docx':
        case 'txt':
          return [
            {
              icon:
                <div style={{ width: 100 }} >
                  <DescriptionIcon />
                </div>
            }
          ];


        case 'png':
        case 'jpg':

          return [
            {
              icon:
                <div style={{ width: 100 }} >
                  <ImageIcon />
                </div>
            }
          ];

        default:
          return [
            {
              icon:
                <div style={{ width: 100 }}>
                  <ShortTextIcon />
                </div>

            }
          ];
      }
    }
  }

  handleRecipient = (text,row) =>  //handle edit of recepient
  {
    this.props.handleRecipient(text,row)
  }


  handleInfo = (text) =>  //handle information dialog (i) column
  {
    this.props.handleInfo(text)
  }

  handleUserList = (row) =>  //handle information dialog (i) column
  {
    //console.log(row)
    this.props.handleUserList(row.usergroup);
  }


  //#region proxies
  //proxy to access triggerdownloadfilelink from DownloadFiles.js
  downloadFile = (FullName, FileName) => {
    this.props.triggerDownloadFileLink(FullName, FileName)
  }

  //proxy to access handleDelete from UploadFiles.js
  deleteFile = (FullName, Sender) => {
    this.props.handleDelete(FullName, Sender)
  }
  //#endregion

  onSelectedRowsChange = (selectedRows) => {
    //console.log(selectedRows )

    //this.setState({ selectedRows:selectedRows });
  };
  //#region filter

  handleFilterChange = (filter) => {
    const newFilters = { ...this.state.filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  };


  onToggleFilter = () => {
    this.setState(prevState => {
      const enableHeaderFilters = !prevState.enableHeaderFilters;

      if (!enableHeaderFilters) {
        return {
          enableHeaderFilters,
          filters: {}
        };
      }

      return { enableHeaderFilters };
    });
  };

  //#endregion

  render() {
    return (
      <div style={{paddingLeft:"1rem"}}>
        <Toolbar
          enableFilter
          onToggleFilter={this.onToggleFilter}
          style={{ height: 1}}
        />
        <DataGrid
          //rowKey="id"
          onGridSort={this.handleGridSort}
          columns={this.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          sortDirection={this.state.sortDirection}
          sortColumn={this.state.sortColumn}
          minHeight={500}
          getCellActions={this.getCellActions}
          enableCellSelect
          enableHeaderFilters={this.state.enableHeaderFilters}
          onAddFilter={this.handleFilterChange}
          minColumnWidth={100}
        //selectedRows={this.state.selectedRows}
        //onSelectedRowsChange={this.onSelectedRowsChange}
        />
      </div>
    );
  }
}

export default DataGridBase;
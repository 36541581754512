import axios from "axios";

//this are plain js functions / functional components. 
axios.defaults.headers.get['x-api-key'] = process.env.REACT_APP_API_KEY; //only affects get requests
// axios.defaults.headers.get['Content-Type'] = 'application/json';//only affects get requests

///USE THE INTERCEPTOR TO ACCESS INDIVIDUAL SECTIONS OF HTTP REQUEST 
// axios.interceptors.request.use(req => {
//     console.log(`${req.method} ${req.url}`);
//     // Important: request interceptors **must** return the request.
//     return req;
//   });

//gets file list available to user (just the list and not the actual files)
export const FileList= (data,callback)=>
{
    var fileName=[];
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/fileList?`,{
            params : {
                username:data.username
            }
        }).then(res => {
            console.log(res)
                fileName=res.data;
                 //console.log(res.data)
                if(callback!=null)  callback(fileName)

            }).catch(err => {
                console.log(err.response); });
}


//gets file list available to user (just the list and not the actual files)
export const MyUploadedFileList= (data,callback)=>
{
    var fileName=[];
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/fileList?`,{
            params : {
                username:data.username
            }
        }).then(res => {
                fileName=res.data;
                 //console.log(res)
                if(callback!=null)  callback(fileName)

            }).catch(err => {
                console.log(err.response); });
}

//gets list of contacts available to the user (for now refers to employees within same company)
export const fetchUserContacts=( userid,callback)=>
{ 
    //var endpoint =process.env.REACT_APP_BASE_URL+"/api/user?companyid="+companyid
    var endpoint=process.env.REACT_APP_SSO_API+"/usercontactlist?userid="+userid+"&applicationid=1";

    axios.get(endpoint,{
        params : {
            'Content-Type': 'application/json',
            // 'ordercolumn':'username',
            // 'order':'asc',
            // 'index':'0',
            // 'row':'100'
        }
    }).then(res => {
        //console.log(res.data[0].contactlist)
        if(callback!=null)  callback(res.data[0].contactlist)

    }).catch(err => {
        console.log(err.response); });

}

export const fetchUserGroups = (userid,callback)=>
{ 
    //var endpoint =process.env.REACT_APP_BASE_URL+"/api/user?companyid="+companyid
    var endpoint=process.env.REACT_APP_SSO_API+"/group?userid="+userid;

    axios.get(endpoint,{
        params : {
            'Content-Type': 'application/json',
            'ordercolumn':'groupid',
            'order':'asc',
            'index':'0',
            'row':'100',
            'applicationid':'1',
            'applicationname':'filesharing',
            'emailordercolumn':'username',
            'emailordertype':'asc'
        }
    }).then(res => {
        //console.log(res.data)
        if(callback!=null)  callback(res.data)

    }).catch(err => {
        console.log(err.response); });
}




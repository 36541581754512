 import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import clsx from "clsx";
import {Grid,Card,Typography,CardActions,CardMedia,CardActionArea } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';


const styles = theme => ({
  root: {
    flexGrow: 1,
    // justifyContent: "center",
    // alignItems: "center",
  },
  card: {
    flexGrow: 1,
    minWidth: 250,
    minHeight: 250,
    maxWidth:250,
    maxHeight:250,
  },
  grid: {
    minWidth: 275,
    maxWidth:280,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:"75%",
  },

  div: { 
    display: 'block'
  }
  });


class Changelog extends React.Component
{
    state=
    {
      isExpanded:[false,false],

    }

    handleExpandClick=(e,index)=>{ 
      this.props.setTab(index);
        // if(index===0) this.props.history.push( "/"+this.props.role+"/uploadFile");
        // if(index===1) this.props.history.push( "/"+this.props.role+"/downloadFile");
        // if(index===2) this.props.history.push( "/"+this.props.role+"/contacts");
    }

    //sets drawer state. In this case it's an array to cater to multiple drawers with a single function
    toggleDrawer=(e,index)=>
    {
      console.log(index)
      var expanded=this.state.isExpanded.slice();
      expanded[index]=!this.state.isExpanded[index];
      this.setState({isExpanded:expanded});
    }
  
checkFile=()=>

{
//this.props.checkNewFiles();

}


    render()
    {
        const {classes} = this.props;
      
        return(
          <div className={classes.root}>
            <h2 style={{marginLeft:'1rem'}}> {process.env.REACT_APP_VERSION} Changelog </h2> 
            <div>
            {/* <p style={{marginLeft:'1rem'}}><strong>Build 1.0.0</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Added loading icon for downloading feature
<br/>- Fixed bug where downloads page didn't default to NEW when there were new messages.
<br/>- Fixed issue where Groups were always visible.
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.3.0d</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Fixed authentication to correctly check with server if user's session has expired.
<br/>- Overhauled authentication and cookies to reflect server check accurately.
<br/>- Updated Logout button session management to reflect these changes.
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.3.0c</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Updated documentation
<br/>- Fixed contacts so that group displays all users in a popup window.

</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.3.0b</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Fixed upload reset bug.
<br/>- Updated documentation.
<br/>- Notify user if cant remove user.
<br/>- Sort past uploads by date by default.
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.3.0a</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Scaled and adjusted documentation font.
<br/>- bugfix to re-enable proper gdpr/personal data pages .
<br/>- UI on download page has been fixed to follow standard theme.
<br/>- Renaming of Help to User Documentation.
<br/>- Corrected display of Group Organization under contacts.
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.3.0</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Enabled editing of recipients for file owners, under MyUploads.
<br/>- Overhauled contact list to allow SSO controlled groups and contacts , independent of Organization.
<br/>- Implemented help tab, which routes to documentation. Current implementation temporarily uses a pdf document. Will be upgraded later.
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.2.0b</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Removed delete icon for non-owned files (until further implementation)
<br/>- Fixed extra comma in Recipient list for MyUploads.
</p>
            <p style={{marginLeft:'1rem'}}><strong>Build 1.2.0a</strong> </p>
<p style={{marginLeft:'1rem'}}>
- Implemented group based file transactions. (send files to multiple groups and individual users)
<br/>- Implemented autocomplete API to enable chips based UI selection menu for viewing/editing/removing users.
<br/>- Remove access to account management and personal profile. (Use SSO instead going forward)
<br/>- Fixed time based sorting to Reflect month correctly. (not by alphabetical order)
<br/>-Reorganized data structure to accomodate new parameters from API. (to prepare for logging info)
<br/>-Implemented in App changelog.
<br/>-Implemented total download count field for MyUploads.
<br/>-Implemented Group view with users in contact list.
<br/>-Implemented file type icon.
<br/>-Upgraded Recipient list to reflect groups.
</p>
          <p style={{marginLeft:'1rem'}}><strong>Build 1.1.0b</strong> </p>
            <p style={{marginLeft:'1rem'}}>
              
<br/>-Overhauled major data tables to react-data-grid tables.
<br/>-Implemented contact list.
<br/>-Implemented table sorting and filtering.
<br/>-Implemented table resizing.
<br/>-Implemented initial version of "new file" detection and notification.
<br/>-Implemented new file counter on landing page "Download" card.
<br/>-Implemented "All Files" & "New Files" filter Buttons to Download Files. (defaults to new if new files available)
<br/>-Set all timestamps to 24hr system.
<br/>-Presort data to descending date/ time.
<br/>-Implemented "Past File Uploads" tab to uploads page.
<br/>-Temporarily removed loading spinner .
</p>
<p style={{marginLeft:'1rem'}}> <strong>Build 1.0.0</strong></p>
<p style={{marginLeft:'1rem'}}>
<br/>-Upload files 
<br/>-Download files
<br/>-Delete files (Uploader only check) 
<br/>-Integration with HoF SSO.
</p> */}

</div>
   
</div>
        )
    }
}
export default withRouter(withStyles(styles)(Changelog));
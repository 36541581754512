import React, { Component } from 'react';
import axios from "axios";
import{determineFileType,determineFileExt,trimFileName} from '../../components/regex.js';
import {Progress} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import'./DownloadFiles.css';
import {Dialog ,DialogActions, DialogContent, DialogContentText,DialogTitle, Tab, Tabs} from'@material-ui/core';
import {Button, IconButton} from 'horizon-components-react';
import DataGridTable from "../../components/DataGrid/DataGrid.js"
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { TabContainer } from 'react-bootstrap';
import LoadingIcon from '../../components/LoadingIcon.js'
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useParams } from 'react-router';
import { withRouter } from 'react-router'


// axios.defaults.headers.common['x-api-key'] = "SREpQuZsAu4DRF58K9r261zmjuptc30g3Vl8IlXB"; 
axios.defaults.headers.common['Content-Type'] = 'application/json';

const styles = theme => ({
    button:
  {
    color:'white', 
    //fontSize:'0.67em', 
    fontWeight: 'bold',
  },

  buttonSelected:
  {
    color:'#FBCA33',  //from horizon theme color ...we should use theme context in the future
    //fontSize:'0.67em', 
    fontWeight: 'bold'
  },

  default_tabStyle:{
    fontSize:"0.80rem",
    color: 'white',
    backgroundColor: '#00205b',
    opacity: 1,
    textTransform:'capitalize'
   },
    active_tabStyle:{
    fontSize:"0.80rem",
    fontWeight:'bold',
    color: '#fbca33',
    backgroundColor: '#00205b',
    opacity: 1,
    textTransform:'capitalize',
   }
    });

class DownloadFiles extends Component{

    state = {
        page:0, //current page for pagination
        rowsPerPage:10, //total rows to display per page
        rows:[], //content per row
        rowsNew:[], //new files
        rowsAll:[],
        recipient:null, //
        loaded: 0,
        dialogIsOpen:false, //we may want to move this to a standalone functional component that takes in a title and content as variables.
        fileRef:null,
        isReady:false,
        isAllFiles:null,
        //CardIsOpen:false,
        InfoIsOpen:false,
        InfoText: null,
        activeTab:0,
        isDownloading:false,
        filename: ''
    }

    componentDidMount()

    {
        console.log(window.location.href)
        let fileName = '';
        if (this.props.location.state) {
            const searchParams = new URLSearchParams(this.props.location.state.search);
            fileName = searchParams.get("filename");
            this.setState({filename:fileName})
        }

        setTimeout(()=>this.createData(),10); //there should be a better way to do this? eg flag system?
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.fileList !== prevProps.fileList) {
          this.createData();
      }
    }

    render()
    {            
        // const search = this.props.location.search;    const name = new URLSearchParams(search).get("name");
        const {classes} = this.props;
        return(
            <div>
                <LoadingIcon isLoading={this.state.isDownloading} timer={15000} callback={this.setIsDownloading.bind(this)}/>
                <h2 style={{paddingLeft:'1rem'}}> File Repository  </h2>

                <Tabs  // changed to tabs method for download pages
                    style={{paddingLeft:'1rem'}}
                    value={this.state.activeTab}
                    onChange={this.tabChange}
                    TabIndicatorProps={{style: {backgroundColor: '#fbca33'}}}
                >
                    <Tab className= // View all files
                    {this.state.activeTab===0 ? classes.active_tabStyle :classes.default_tabStyle}label= "All files" >
                    </Tab>
                
                    <Tab className=    // View new files
                    {this.state.activeTab===1 ? classes.active_tabStyle :classes.default_tabStyle}label= " New Files" >
                    </Tab>
                </Tabs>

                { // Download all files Tab Content 
                   this.state.activeTab ===0 && 
                    <TabContainer>
                         {<DataGridTable nameOfFile={this.state.filename} createData={this.createData.bind(this)} intialRows={this.state.rowsAll} handleInfo ={this.handleInfo.bind(this)} handleDelete={this.handleDelete.bind(this)} triggerDownloadFileLink={this.triggerDownloadFileLinkv2.bind(this)} />}
                    </TabContainer>
                }
                { // Download new files Tab Content 
                    this.state.activeTab ===1 && 
                    <TabContainer>
                         {<DataGridTable intialRows={this.state.rowsNew} handleInfo ={this.handleInfo.bind(this)} handleDelete={this.handleDelete.bind(this)} triggerDownloadFileLink={this.triggerDownloadFileLinkv2.bind(this)} />}
                    </TabContainer>
                }

                {/* <Button className={this.state.isAllFiles?classes.buttonSelected:classes.button} contained ={true} onClick={(e)=>this.toggleAllFiles(true)} color ="secondary"> All Files</Button>
                <Button className={!this.state.isAllFiles?classes.buttonSelected:classes.button} contained ={true} onClick={(e)=>this.toggleAllFiles(false)} color ="secondary"> New Files</Button> */}

            {/* {this.state.isReady && this.generateTable()} */}
            {/* {this.state.isReady &&<DataGridTable intialRows={this.state.rows} handleInfo ={this.handleInfo.bind(this)} handleDelete={this.handleDelete.bind(this)} triggerDownloadFileLink={this.triggerDownloadFileLinkv2.bind(this)} />} */}
            {/* {this.state.isReady && <DataGridBase intialRows={this.state.rows} handleDelete={this.handleDelete.bind(this)} triggerDownloadFileLink={this.triggerDownloadFileLink.bind(this)} columns={columns}/>} */}
            { this.showDialog()}
            { this.showInfo()}
            {/* { this.showCard()} */}
            {this.state.loaded>0 &&
                <Progress max="100" color="info" value={this.state.loaded>0? Math.round(this.state.loaded):0}
                className="text-center"
                >{this.state.loaded>0?Math.round(this.state.loaded):0}%</Progress>
            }
       
            </div>
        )
    }


    tabChange=(event, value, index)=>
    {
        this.setState({ activeTab:value });
    }

    toggleAllFiles=(isAll)=>
    {
        var rows;
        if(isAll) {
            rows=this.state.rowsAll.slice();
            this.tabChange(null,0);
        }
        else {
            rows=this.state.rowsNew.slice();
            this.tabChange(null,1);
        }
        this.setState({rows:rows,isAllFiles:isAll})
    }

    handleInfo=(text)=>
    {this.setState({ dialogIsOpen:false, InfoIsOpen:true, InfoText: text }
        )
    
}
    handleCard=()=>
    {this.setState({ dialogIsOpen:false, CardIsOpen:true }
        )
    
}

    //handles setting of new page (pagination)
    handleChangePage = (e,newPage) =>this.setState({page:newPage});

    //handles setting of new numver of rows per page (pagination)
    handleChangeRowsPerPage = event => this.setState({rowsPerPage:+event.target.value ,page:0});

    handleDeleteOld=(fileName,ID)=>
    {

        if(this.props.fileList[ID].Sender_Username===this.props.user.username)  //Success case .User is file uploader
        {
            this.setState({dialogIsOpen:true,fileRef:fileName})
        }
        else
        {
            this.props.sendAlert("Only the uploader can delete the file.","ErrorAlert",2000)
        }
        //this.triggerDeleteFile(fileName)
    }
    handleDelete=(fileName,Sender)=>
    {
        if(Sender===this.props.user.username)  //Success case .User is file uploader
        {
            this.setState({dialogIsOpen:true,fileRef:fileName})
        }
        else
        {
            this.props.sendAlert("Only the uploader can delete the file.","ErrorAlert",2000)
        }
        this.triggerDeleteFile(fileName)
    }

    // "cleans" the data into a format that can be fed into the dynamic table . Makes use of regex function to split the FileKey string into respective fields
    createData=() =>{
        this.setState({isReady:false})
        if(this.props.fileList==null) return;
        var rowsarr=[]
        var rowsarrNew=[]

 

        
        this.props.fileList.map((file,index) => {
            var fileName=file.fileKey;
            if(fileName != null){
                let UUID =file.UUID;
                let FileName  = trimFileName(fileName);
                let FullName=fileName;
                let UploadDate = file.dpfilestatusuploaddate ;
                 UploadDate = moment.utc(UploadDate).local()
                 let UploadTime=moment(UploadDate,'MMMM Do YYYY, HH:mm:ss a').valueOf();
                 //console.log(UploadTime)
                //let UploadTime = moment(UploadDate).format('MMMM Do YYYY, HH:mm:ss a');
                 //use moment library to handle date and time formatting
                let ID=index +1;
                let Category=determineFileExt(fileName);
                let Type=determineFileType(fileName);
                let Sender=file.senderusername;
                let Fslistid =file.recipientlist[0].fslistid; //we pull out one of the fslistid since they all point to the same file(used by backend to differentiate per user)
                let FileKey=file.fileKey;
                rowsarr.push({UUID,ID,FullName,FileName,UploadTime,Category,Type,Sender,Fslistid,FileKey})

                if(file.isnew &&  Sender !== this.props.user.username)  
                {       
                     
                    rowsarrNew.push({UUID,ID,FullName,FileName,UploadTime,Category,Type,Sender,Fslistid,FileKey})
                }
            }  
        })
       

        this.setState({rowsAll:rowsarr,rowsNew:rowsarrNew})

        // this.toggleAllFiles((this.state.rowsNew.length<=0) )   //if there are new files, default to showing this
        
        setTimeout(()=> this.setState({isReady:true}),1000)
    }
    

    //#region DB
    //gets signedurl from server to allow larger file downloads hands over url to triggerDownloadFileCont
    triggerDownloadFileLink= (fileName,trimmedFileName) =>{
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/api/excel/download?fileKey=${fileName}`,
            method: 'GET',
            responseType: 'text', // important
        }).then((response) => {
            this.triggerDownloadFile(response.data,trimmedFileName)
        });
        }

    //gets signedurl from server to allow larger file downloads hands over url to triggerDownloadFileCont
    triggerDownloadFileLinkv2= (UUID,FileKey,Fslistid,trimmedFileName) =>{
        this.setIsDownloading(true);
        axios({
            url: `${process.env.REACT_APP_BASE_URL}/api/filesharing/download`,
            method: 'POST',
            data:{
                dpfilestatusuuid:UUID,
                dpfilestatusfilekey:FileKey,
                fslistid:Fslistid,
                username:this.props.user.username,
            },
            //responseType: 'text', // important
        }).then((response) => {
             //console.log(response.data)
             if(response.data.url!=null)this.triggerDownloadFile(response.data.url,trimmedFileName,UUID)
             else console.log(response.data.message)
        });
        }

    //actually download the file from the S3 signed url returned above
    triggerDownloadFile = (signedurl,trimmedFileName,UUID) =>{
        axios({
            url: signedurl,
            method: 'GET',
            responseType: 'blob', // important
            onDownloadProgress: ProgressEvent => {
                let arr = this.state.loaded;
                arr = (ProgressEvent.loaded /(ProgressEvent.total)*100);
                this.setState({
                    loaded : arr
                }) }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', trimmedFileName);
            document.body.appendChild(link);
            link.click();
        }).then( ()=>{
            this.props.sendAlert("File download started","SuccessAlert",2000)
            this.props.fileHasBeenDownloaded(UUID);
            this.setState({loaded:0})});
            this.setIsDownloading(false)
        }

    //delete file from S3
    triggerDeleteFile = (fileName)=>{
         this.props.showLoading(true);
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/fileStatus/deleteFileKeySharing?fileKey=${fileName}`)
        .then(res => {
            this.props.showLoading(false);
            this.props.triggerUpdate();
            this.props.sendAlert("File has been deleted","SuccessAlert",2000)
        }).then(setTimeout(this.createData,3000))
        .catch(err=>console.log(err));
        // this.props.showLoading(true);
    }
    //#endregion

    showDialog()
    {
        return(
            <Dialog
        open={this.state.dialogIsOpen}
         onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Selected File?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleClose(e,false)} >
            CANCEL
          </Button>
          <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleClose(e,true)} autoFocus>
           YES
          </Button>
        </DialogActions>
      </Dialog>
        )
    }

    handleClose=(e,del)=>  //closes dialog menu
    {
        this.setState({dialogIsOpen:false})
        if(del) { 
            this.triggerDeleteFile(this.state.fileRef) //calls the api to delete the file from aws
            //console.log(this.state.fileRef)
        }
        else ;
        setTimeout(()=>this.setState({fileRef:null}),1000);
    }

    setIsDownloading=(isDownloading)=> //sets/clears the isDownloading flag to prevent user from over clicking
    {
        this.setState({isDownloading:isDownloading});
    }

//     handleCardClose=()=>
//     {
//         this.setState({CardIsOpen:false})
//         // if(del) { 
//         //     this.triggerDeleteFile(this.state.fileRef) //calls the api to delete the file from aws
//         //     //console.log(this.state.fileRef)
//         // }
//         // else ;
//         // setTimeout(()=>this.setState({fileRef:null}),1000);
//     }

//     showCard()
// { 
// return (

//         <Dialog
//     open={this.state.CardIsOpen}
  
//      onClose={this.handleCardClose}
//     aria-labelledby="alert-dialog-title"
//     aria-describedby="alert-dialog-description"
//   >
//     <DialogTitle id="alert-dialog-title">{"Sender Information"}</DialogTitle>
//     <DialogContent>
//       <DialogContentText id="alert-dialog-description">
        
//       </DialogContentText>
//     </DialogContent>
//     <DialogActions>
//     {/* <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleCardClose(e,false)} >
//         VIEW MORE Information
//       </Button> */}
//       <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleCardClose(e,true)} autoFocus>
//        DONE
//       </Button>
//     </DialogActions>
//   </Dialog>
//     )

// }

handleInfoClose=()=> //hide contact information 
{
    this.setState({InfoIsOpen:false})
    // if(del) { 
    //     this.triggerDeleteFile(this.state.fileRef) //calls the api to delete the file from aws
    //     //console.log(this.state.fileRef)
    // }
    // else ;
    // setTimeout(()=>this.setState({fileRef:null}),1000);
}

showInfo=()=> //show contact information "calendar icon"
{  
  
return (

        <Dialog
    open={this.state.InfoIsOpen}
     onClose={this.handleInfoClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Contact Information"}</DialogTitle>
    <DialogContent>
   {this.state.InfoText}
    </DialogContent>
    <DialogActions>
    {/* <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleCardClose(e,false)} >
        VIEW MORE Information
      </Button> */}
      <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleInfoClose(e,true)} autoFocus>
       DONE
      </Button>
    </DialogActions>
  </Dialog>
    )

}




}
export default withRouter(withStyles(styles)(DownloadFiles)); 
import React from 'react';
import {Button} from 'horizon-components-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import './style.css';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import axios from "axios";


const styles = theme => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing.unit * 3,
    },
  });
  

class PersonalData extends React.Component {
  state = {
    open: true,
    scroll: 'paper',
    // isAgreed:false, 
    checkbox1: false,
    checkbox2: false,
    userapplicationroleackexportcontrol:this.props.location.state.userapplicationroleackexportcontrol,
    userapplicationroleackpersonaldatareused:0,
    userapplicationroleackpersonaldatacollected:0
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleAgree = () => {    
    let isAgreed= 1      //for agree button
    this.setState({ open: false, userapplicationroleackexportcontrol:isAgreed, userapplicationroleackpersonaldatareused:isAgreed,  userapplicationroleackpersonaldatacollected:isAgreed });
    //this.props.history.push({pathname:'/auth', state:{userapplicationroleackexportcontrol:isAgreed,userapplicationroleackpersonaldatareused:isAgreed,userapplicationroleackpersonaldatacollected:isAgreed }});

    
    //PATCH data to sso api with useracknowledgement of disclaimers
    // KEY VALUES SHOULD BE STRINGS BECAUSE OF ARRAY. THIS WILL BE THE API STANDARD IN THE FUTURE
    //ONLY GET CAN USE "PARAM" AS KEYWORD TO INSERT VALUES TO HTTP REQ. OTHER REQUESTS INSERT VALUE DIRECTLY AS BELOW
    axios.patch(`${process.env.REACT_APP_SSO_API}/useracknowledgement`,[{
          
            "userapplicationroleid":this.props.location.userapplicationroleid,
            "userapplicationroleackexportcontrol":isAgreed,
            "userapplicationroleackpersonaldatareused":isAgreed,
            "userapplicationroleackpersonaldatacollected":isAgreed,
            "logintoken":this.props.location.logintoken , 
            "roletoken":this.props.location.roletoken 
    }]
    ).then(res => {
       //console.log(res.data[0])
      this.props.history.push({pathname:'/auth', newRoleToken:res.data[0].roletoken})

    }).catch(err => {
        console.log(err.response); 
    });


  };

  handleDisagree = () => {  
    let isAgreed= 1          //for disagree button
    this.setState({ open: false,userapplicationroleackexportcontrol:isAgreed });
    this.props.history.push({pathname:'/disclaimer', state:{userapplicationroleackexportcontrol:isAgreed}})
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  componentDidMount()
  {
    axios.defaults.headers.patch['x-api-key'] = process.env.REACT_APP_API_KEY; //only affects patch requests
  }

  componentDidUpdate()
  {
    if ((this.state.checkbox1===true) && (this.state.checkbox2 === true)){
      if((this.state.userapplicationroleackexportcontrol===1) && (this.userapplicationroleackpersonaldatacollected && this.userapplicationroleackpersonaldatareused === 0))
        this.setState({
            userapplicationroleackpersonaldatareused:1,
            userapplicationroleackpersonaldatacollected:1
        }
)
    }
  }


  render() {
    const { classes } = this.props;
    const { checkbox1, checkbox2 } = this.state;
    const error = ([checkbox1,checkbox2].filter((v) => v).length < 2);  // must check min. 1 checkbox
    return (
      <div>
        <div class = "overlay" >
        <Dialog
          maxWidth={'xl'}
          fullWidth={'true'}
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Personal Data Disclaimer</DialogTitle>
          <DialogContent>
            <DialogContentText>
           <p> Your privacy and personal data is an important concern to which Airbus pays special attention throughout our business processes. This privacy notice is to let you know how Airbus looks after your personal information. This includes what you tell us about yourself, what we learn by having you as an employee. This notice also tells you about your privacy rights and how the law protects you.
Airbus SAS and its affiliates (also known as, “Airbus”, or “we” or “us”) is committed to protecting the rights of individuals in line with the General Data Protection Regulation (reference EU2016/679) of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data (hereinafter referred as : ‘GDPR’) as well as each applicable national Personal Data protection laws and regulations (collectively referred as “Data Protection Laws and Regulations”).</p>
  
  <p><b>Which sources and what Personal Data do we use?</b></p>

  <p>When you use the HOF Data Exchange application, we will collect, use and process any Personal Data you provide, such as file transfer and management activities (upload, download) and the relevant IT identifiable data (time of connection, IP address, user account email, password).</p>


  <p><b>Who will receive your Personal Data?</b></p>
<p></p>•Authorised persons working for or on behalf of Airbus
<p></p>•Airbus Group SE and affiliates of Airbus, on a need-to-know basis for the purposes as outlined in this Privacy Notice
<p></p>•Our agents, service providers and advisers (e.g. Third party service providers and advisers providing the variety of products and services we need such as IT maintenance and support, procurement services, compliance and security services, etc.)
<p></p>•Other authorised third parties in connection with a reorganisation or sale of Airbus businesses and/or assets
<p></p>•Law enforcement or government authorities where necessary to comply with applicable law.
<p></p>



<p><b>What are the purposes of the processing of your Personal Data?</b></p>
<p></p>We use your Personal Data for the following purposes such as: User management, IT security , to comply with applicable legal obligations, including responding to an authority or court order or discovery request Professional whistleblowing, Reporting of professional and ethics incidents, Corporate audits, Export control, Litigation management, IP rights management, Company law duties and mandates management, where we believe it is necessary to investigate, prevent or take action regarding illegal & non-compliant activities, suspected fraud, emergency and crisis management, risks management or situations involving potential threats to the safety of any person or violations of policies, directives and procedures.
<p></p>




<p><b>Will your Personal Data be transferred to a third country outside the European Economic Area (EEA)?</b></p>
<p>Airbus processes your Personal Data mostly in the EEA. On occasion Personal Data is transferred to Airbus SE or affiliates of Airbus, on a need-to-know basis, including entities outside the EEA. This transfer is subject to appropriate safeguards, and through the legal framework of our Binding Corporate Rules, that can be found on our website www.airbus.com or viewed here Airbus BCR's or through alternative contractual frameworks where a third party is engaged to help us providing web-services to you.</p>
<p><b>Which countries will Airbus transfer Personal Data to?</b></p>
<p>Our Binding Corporate Rules allow us to transfer Personal Data within our international organization, and they include a list of countries (below) which are structured to allow us to transfer Personal Data to the countries where we have a presence. For Airbus, France and Germany are where most of our processing of personal information takes place and below is a list of countries where Airbus operates, Algeria, Australia, Belgium, Brazil, Canada, Chile, China, Czech republic, Denmark, Egypt, Finland, France, French Guyana, Germany, Greece, Hong Kong, Hungary, India, Indonesia, Ireland, Italy, Japan, Kazakhstan, Libya, Malaysia, Mexico, Morocco, Netherlands, New Zealand, Norway, Oman, Philippines, Poland, Qatar, Romania, Russia, Saudi Arabia, Singapore, Slovakia, South Africa, South Korea, Spain, Sweden, Taiwan, Tanzania, Thailand, Tunisia, Turkey, United Kingdom, United States of America, Uruguay, United Arab Emirates, Vietnam.</p>

<p><b>How long will your Personal Data be stored?</b></p>
<p>We process and store your Personal Data for as long as is required to meet our contractual and statutory obligations (typically 3 to 5 years). The retention of your Personal Data is determined by Data Protection Laws and Regulation and by our procedures or processes adopted in accordance with Data Protection Laws and Regulation.</p>
<p></p>
<p>You may at any time exercise your data protection rights:
<p></p>• Right to access/obtain a report detailing the information held about you: You have the right to obtain confirmation as to whether or not your Personal Data is being processed by Airbus and if so, what specific data is being processed.
<p></p>• Right to correct Personal Data: You have the right to change any inaccurate Personal Data concerning you.
<p></p>• Right to be forgotten: In some cases, for instance, when the Personal Data is no longer necessary in relation to the Purposes for which they were collected, you have the right for your Personal Data to be erased.
<p></p>• Right to stop the processing of your data: You have the right to restrict the processing of your Personal Data by Airbus, for instance when the processing is unlawful and you oppose the erasure of your Personal Data. In such cases, your Personal Data will only be processed with your consent or for the exercise or defense of legal claims.
<p></p>• Right to data portability: Under some circumstances provided by law, you have the right to receive the Personal Data concerning you in a structured, commonly used and machine-readable format and/or transmit those Personal Data to another data controller.
<p></p>• Right to object and to withdraw consent
<p></p>
<p><b>How can I contact Airbus in respect of my Personal Data?</b></p>
<p>If you are unhappy with the way in which your Personal Data has been processed or should you have questions regarding the processing of your Personal Data, you may get in touch through AIRBUS/Hangar of the Future, who is available for enquiries or complaints, at the following email address: hof@airbus.com</p>
<p><b>Can I ask for assistance to the competent authorities?</b></p>
<p>FRANCE: CNIL: Supervisory Authority France GERMANY: Datenschutz-hamburg: Supervisory Authority for Airbus Operations GmbH or lda Bayern: Supervisoy Authority for Airbus Companies in Bavaria, Germany or any other competent regional German authority as the case may be, SPAIN: AGPD.Supervisory Authority Spain UK: ICO: Supervisory Authority UK</p>
<p><b>Security</b></p>
<p>We use technical and organizational security measures in order to protect the data we have under our control against accidental or intentional manipulation, loss, destruction and against access by unauthorized persons. Our security procedures are continually enhanced as new technology becomes available.</p>
<p><b>Modification of the Privacy Notice</b></p>
<p>AIRBUS/Hangar of the Future will update this Privacy Notice from time to time in order to reflect the changes in our practices and services and also to remain compliant to Data Protection Laws and Regulations. We will inform you of any substantial modification in how we process your Personal Data.</p> </p>
</DialogContentText>
          </DialogContent>
          <div class={classes.root}> 
        <FormControl  required error={error} component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">I agree with “Personal Data Disclaimer”</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={checkbox1} onChange={this.handleChange('checkbox1')} value="checkbox1" />
              }
              label="I agree that my data will be collected by Airbus"
            />
            <FormControlLabel
              control={
                <Checkbox checked={checkbox2} onChange={this.handleChange('checkbox2')} value="checkbox2" />
              }
              label="I agree that my data may be reused by Airbus"
            />
         
          </FormGroup>
          {/* <FormHelperText>You can display an error</FormHelperText> */}
        </FormControl>
      
        </div>
          <DialogActions>
            <div>
            <Button contained color="error" onClick={this.handleDisagree} style={{marginRight:"0.5rem"}}>
              NO
            </Button>
            <Button disabled={(this.state.checkbox1 && this.state.checkbox2) !==true} contained color="success" onClick={this.handleAgree}>
              YES
            </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      </div>
    );
  }
}
PersonalData.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(PersonalData);

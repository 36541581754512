import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  });

  ///LoadingIcon component that accepts (isLoading,timer,callback)
  class LoadingIcon extends React.Component
  {
      state=
      {
        isLoading:false,
        timerID:null,
      }

      componentDidUpdate(prevProps)
      {
        if(this.props.isLoading!==prevProps.isLoading)
        {
            this.setLoading(this.props.isLoading)
        }
      }


      setLoading=(isLoading)=>
      {
        this.setState({isLoading})
        if(this.props.timer &&this.props.timer>0) this.setTimer(this.props.timer)
        if(isLoading===false &&this.state.timerID)this.clearTimer();
      }

      //timeout for loading icon (to prevent infinite loading)
      setTimer=(timer)=>
      {
          var timerID =setTimeout(
              ()=>
              {
                this.setState({timerID:null,isLoading:false})
                if(this.props.callback) this.props.callback(false)
              },timer
          )       
          this.setState({timerID})   
      }

      //clear previously setTimer 
      clearTimer=()=>
      {
        clearTimeout(this.state.timerID)
      }

      render()
      {
        const { classes } = this.props;
        return(
            <Backdrop className={classes.backdrop} open={this.state.isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>)
      }

  }

  export default withStyles(styles)(LoadingIcon);
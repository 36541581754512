import React, { useState } from "react";
// import DataGrid from "react-data-grid";
import 'react-data-grid/dist/react-data-grid.css';
import "./styles.css";
import { Delete } from '@material-ui/icons/';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons';
import CustomSimpleCellFormatter from './CustomSimpleCellFormatter';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import ShortTextIcon from '@material-ui/icons/ShortText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import moment from 'moment'
import SlideshowIcon from '@material-ui/icons/Slideshow';
import CustomFilter from 'react-data-grid'

const { Selectors } = Data;
const TimeFormatter = ({ value }) => {
  var m = moment(value);
  var s = m.format("MMMM Do YYYY, HH:mm:ss a");
  return s;
};

class DataGridTable extends React.Component {

  columns = [
    // SelectColumn,
    {
      key: 'FileName',
      name: 'File Name',
      sortable: true,
      filterable: true,
      visible: true,
      // filterRenderer: (props) => <CustomFilter initialFilterTerm="value" {...props} />,
      formatter: CustomSimpleCellFormatter,
      // events: {
      //   onMouseOver: function(ev) {
      //     console.log(this);
      //   }
      // }

    },

    {
      key: 'FileType',
      name: 'FileType',
      width: 100,
      // sortable: true,
      // filterable: true,
      visible: true,
      formatter: CustomSimpleCellFormatter,
    },

    {
      key: 'UploadTime',
      name: 'Upload Time',
      sortable: true,
      filterable: true,
      visible: true,
      formatter: TimeFormatter,
    },
    {
      key: 'Sender',
      name: 'Sender',
      sortable: true,
      filterable: true,
      visible: true,
      formatter: CustomSimpleCellFormatter,
    },

    // {
    //   key: 'Information',
    //   name: "Information",
    //   width: 80,
    //   sortable: true,
    //   filterable: true,
    //   formatter: CustomSimpleCellFormatter,
    // },

    {
      key: 'Download',
      name: 'Download',
      width: 80,
      sortable: false,
      visible: true,
      formatter: CustomSimpleCellFormatter,
    },
    // {
    //   key: 'Delete',
    //   name: 'Delete',
    //   width: 80,
    //   sortable: false,
    //   visible: true,
    //   formatter: CustomSimpleCellFormatter,
    // }
  ];

  state = {
    originalRows: this.props.intialRows, //initial rows passed in from downloadfiles
    rows: this.props.intialRows, //clone of originalRows , used for sorting
    filters: {"FileName":{"filterTerm":this.props.nameOfFile,"column":{"key":"FileName","name":"File Name","sortable":true,"filterable":true,"visible":true,"width":495,"idx":0,"left":0}}}, //search filters
    // filters: {}, //search filters
    enableHeaderFilters: false,
    selectedRows: new Set(),
    sortColumn: "UploadTime",//null, //this hardcode should not be necessary , but sortDescendingFirst is not working
    sortDirection: "DESC",//null,

  }

  componentDidUpdate(prevProps) {
    if (this.props.intialRows !== this.state.originalRows) this.setState({ originalRows: this.props.intialRows, rows: this.props.intialRows })
    if (this.props.nameOfFile !== prevProps.nameOfFile) {
      this.setState({    filters: {"FileName":{"filterTerm":this.props.nameOfFile,"column":{"key":"FileName","name":"File Name","sortable":true,"filterable":true,"visible":true,"width":495,"idx":0,"left":0}}}    })
    }
  }

//   componentDidMount(){
// this.handleFilterChange()

//   }


  //handles sorting of columns by asc,desc, or default
  handleGridSortOld = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      }
      if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    };

    const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);

    this.setState({ rows, sortColumn, sortDirection });
  };

  handleGridSort = (sortColumn, sortDirection) => {
    this.setState({ sortColumn, sortDirection });
  };

  //#region getters
  getRows = () => {
    return Selectors.getRows(this.state);
  };

  getSize = () => {
    return this.getRows().length;
  };

  rowGetter = (rowIdx) => {
    const rows = this.getRows();
    return rows[rowIdx];
  };

  // onCellSelected = ({ rowIdx, idx }) => {
  //   this.handleCard()
  // };

  //#endregion

  //used by react-data-grid to assign clickable actions to cell.
  getCellActions = (column, row) => {

    if (column.key === 'Download') { //DOWNLOAD COLUMN
      return [
        {
          icon: <CloudDownloadIcon style={{ width: 100 }} />,
          callback: () => { this.downloadFile(row.UUID, row.FileKey, row.Fslistid, row.FileName) }
        },
      ]; //square bracket is used as a second button layer can be added in 
    }

    else if (column.key === 'Delete') { //DELETE COLUMN
      return [
        {
          icon: <Delete style={{ width: 100 }} />,
          callback: () => { this.deleteFile(row.FullName, row.Sender) }
        },
      ]; //square bracket is used as a second button layer can be added in 
    }

    else if (column.key === ('Sender')) {
      return [
        {
          icon: <PermContactCalendarIcon style={{ width: 100 }} />,
          callback: () => { this.handleInfo() }
        },
      ];
    }

    // else if (column.key === 'Information' ) { //INFO (I) COLUMN    // no need info column currently
    //   return [
    //     {
    //       icon: <InfoIcon style={{ width: 100  }} />,
    //       callback: () =>  { this.handleInfo(row.FileName) }
    //     },
    //   ]; //square bracket is used as a second button layer can be added in 
    // }

    else if (column.key === 'FileType') {   //file icons called here

      switch (row.Category) {
        case 'pdf':
          return [
            {
              icon:
                <div style={{ width: 100 }} >
                  <PictureAsPdfIcon />
                </div>
            }
          ];


        case 'xls':
        case 'xlsx':
          return [
            {
              icon:
                <div style={{ width: 100 }}>
                  <ListAltIcon />
                </div>

            }
          ];


        case 'ppt':
        case 'pptx':
          return [
            {
              icon:
                <div style={{ width: 100 }}>
                  <SlideshowIcon />
                </div>

            }
          ];
        case 'doc':
        case 'docx':
        case 'txt':
          return [
            {
              icon:
                <div style={{ width: 100 }} >
                  <DescriptionIcon />
                </div>
            }
          ];


        case 'png':
        case 'jpg':

          return [
            {
              icon:
                <div style={{ width: 100 }} >
                  <ImageIcon />
                </div>
            }
          ];

        default:
          return [
            {
              icon:
                <div style={{ width: 100 }}>
                  <ShortTextIcon />
                </div>

            }
          ];
      }
    }

  }


  handleInfo = () => {    
    this.props.handleInfo()
  }

  //#region proxies
  //proxy to access triggerdownloadfilelink from DownloadFiles.js
  downloadFile = (UUID, FileKey, Fslistid, FileName) => {
    this.props.triggerDownloadFileLink(UUID, FileKey, Fslistid, FileName)

  }

  //proxy to access handleDelete from DownloadFiles.js
  deleteFile = (FullName, Sender) => {
    this.props.handleDelete(FullName, Sender)
  }
  //#endregion

  // onSelectedRowsChange = (selectedRows) => {
  //console.log(selectedRows )

  //this.setState({ selectedRows:selectedRows });
  // };
  //#region filter

  handleFilterChange = (filter) => {
    const newFilters = { ...this.state.filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  };


  onToggleFilter = () => {
    this.setState(prevState => {
      const enableHeaderFilters = !prevState.enableHeaderFilters;

      if (!enableHeaderFilters) {
        return {
          enableHeaderFilters,
          filters: {}
        };
      }
      return { enableHeaderFilters };
    });
  };

  //#endregion

  render() {
    return (
      <div style={{paddingLeft:'1rem'}}>
        <Toolbar
          enableFilter
          onToggleFilter={this.onToggleFilter}
          style={{ height: 1 }}
        />


        <DataGrid
          //rowKey="id"
          onGridSort={this.handleGridSort}
          columns={this.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          sortDirection={this.state.sortDirection}
          sortColumn={this.state.sortColumn}
          minHeight={500}
          getCellActions={this.getCellActions}
          enableCellSelect={true}
          enableHeaderFilters={this.state.enableHeaderFilters}
          onAddFilter={this.handleFilterChange}
          minColumnWidth={120}
          onCellSelected={this.onCellSelected}
        //selectedRows={this.state.selectedRows}
        //onSelectedRowsChange={this.onSelectedRowsChange}
        />

      </div>
    );
  }
}

export default DataGridTable;
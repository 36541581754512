import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import './MuiAlert.css';

// function Alert(props) {  

//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {

  

//     },
//   },
// }));

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));


export default function SimpleAlertMessage( props) {  //This function handles the different type of alert messages shown in various context
// useEffect (() => {

//   if(props.timeoutDuration > timer) {
//     setTimer(props.timeoutDuration)
//     setOpen(true)
//     setTimeout(() => {
//       setClose()
//     }, timer);
//   }
// });

  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [timer, setTimer] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // const setClose =()=>{
  //   setOpen(false) 
  //   setTimer(0)
  // }

  return (

    <div >
       
      {props.classType=="ErrorAlert" &&         // error message in red
      <Alert style={{padding:0}} variant="filled"  severity="error" >
      {props.title} 
      </Alert> }

      {props.classType=="WarningAlert" &&      // warning message in yellow
      <Alert variant="filled"  severity="warning">
      {props.title} 
      </Alert> }

      {props.classType=="InfoAlert" &&         // info message in blue
      <Alert variant="filled" severity="info" >
       {props.title} 
      </Alert> }

      {props.classType=="SuccessAlert" &&     // success message in green
      <Alert variant="filled"  severity="success">
       {props.title} 
      </Alert> }

   

    </div>
 );
 
}


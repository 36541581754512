import React, { Component } from 'react';
import axios from "axios";
import { determineFileType, determineFileExt, trimFileName } from '../../components/regex.js';
import { Progress } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MuiTableCell.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button, IconButton } from 'horizon-components-react';
//import DataGridTable from "../../components/DataGrid/DataGrid.js"
import DataGridBase from "../../components/DataGrid/DataGridBase.js"
import moment from 'moment';
import FriendList from '../../components/FriendList.js'
import { ContactsOutlined } from '@material-ui/icons';



const TimeFormatter = ({ value }) => {
  // var m = moment(value);
  // var s = m.format("MMMM Do YYYY, HH:mm:ss a");
 let s =  moment.utc(value).format('DD MMMM YYYY HH:mm') + ' UTC';
  return s;
};


class MyUploads extends Component {

  state = {
    page: 0, //current page for pagination
    rowsPerPage: 10, //total rows to display per page
    rows: [], //content per row
    recipients: [], // all selected recipients from friendlist
    loaded: 0,
    dialogIsOpen: false, //we may want to move this to a standalone functional component that takes in a title and content as variables.
    fileRef: null,
    isReady: false,
    infoIsOpen: false,
    infoText: null,
    recipientIsOpen: false,
    selectedRecipients: [],     // all selected recipients inclusive of currently tagged recipients
    selectedRowRecipient: [],  // current tagged recipients for that specific row 
    recipientListForAdd: [],   // recipients to add for edit recipient transaction
    recipientListForDelete: [], // recipients to delete for edit recipient transaction
    initRecipients: [], // initial tagged recipients for edit recipient transaction
    selectedRow:null,
    sort:{sortColumn: "UploadTime",sortDirection: "DESC"},
    openUploadDialog:true

    // transactionDone: 0,
  }

  componentDidMount() {
   
    setTimeout(() => this.createData()); //there should be a better way to do this? eg flag system?
  }
  handleOpenUploadDialogClose = () => {
    this.setState({openUploadDialog:false})
   }




  render() {
    const defaultColumnProperties = {
      resizable: true,
    };


    var columns = [
      //SelectColumn,
      {
        key: 'FileName',
        name: 'File Name',
        sortable: true, filterable: true,
        resizable: true,


      },

      {
        key: 'FileType',
        name: 'FileType',
        width: 100,
        // sortable: true,
        // filterable: true,
        visible: true,
        // formatter: CustomSimpleCellFormatter,
      },

      {
        key: 'UploadTime',
        name: 'Upload Time (UTC)',
        sortable: true, filterable: true, resizable: true,
        formatter: TimeFormatter,
        sortDescendingFirst: true
      },
      // {
      //   key: 'Receiver',
      //   name: 'Recipients',
      //   sortable: true, filterable: true, resizable: true,

      // },

      // {
      //   key: 'Information',
      //   name: 'Information',
      //   sortable: false, filterable: false, resizable: true,
      //   width: 100,
      // },


      {
        key: 'Delete',
        name: 'Delete',
        width: 100,
        sortable: false, resizable: true,
      },


        {
        key: 'Download',
        name: 'Download file',
        width: 100,
        sortable: false, resizable: true,
      }
    ].map(c => ({ ...c, ...defaultColumnProperties }));;
    
    
    return (
      <div>
          { <Dialog
                    disableBackdropClick="true"
                    open={this.state.openUploadDialog}
                    onClose={this.handleOpenUploadDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Take note"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText style={{color:"black", fontWeight:'bold'}} id="alert-dialog-description">
                      NOTE: Previously uploaded files can only be deleted if they haven’t been ingested into Skywise. If you are unable to delete the file, then the file has been ingested into Skywise. If you need to remove a file that has been ingested already, please create an iTOPS ticket.<br/><br/>
                      <div style={{color:"red"}}>  If you have just uploaded a new file, deletion will only be available until {new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 1, 1, 59)).toLocaleString('en-US', { timeZone: 'UTC' })} UTC  <br/><br/></div>
                      Current UTC date and time: {new Date().toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' })} <br/>
                      Deletion is available until: {new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 1, 1, 59)).toLocaleString('en-US', { timeZone: 'UTC' })} UTC  <br/>

                 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button contained ={true} color ="primary" onClick={this.handleOpenUploadDialogClose} autoFocus>

                       I acknowledge
                      </Button>
                      {/* <Button contained ={true} color ="secondary" onClick={this.onChangesHandler} autoFocus>

New File
</Button> */}
                    </DialogActions>
                  </Dialog>
                }<br/>
        {/* {this.state.isReady && this.generateTable()} */}
        {this.state.isReady && <DataGridBase handleRecipient={this.handleRecipient.bind(this)} handleInfo={this.handleInfo.bind(this)} columns={columns} intialRows={this.state.rows} handleDelete={this.handleDelete.bind(this)} triggerDownloadFileLink={this.triggerDownloadFileLink.bind(this)} sort={this.state.sort} />}
        {/* {this.state.isReady && <DataGridBase intialRows={this.state.rows} handleDelete={this.handleDelete.bind(this)} triggerDownloadFileLink={this.triggerDownloadFileLink.bind(this)} columns={columns}/>} */}
        {this.showDialog()}
        {this.showInfo()}
        {this.showRecipient()}

      </div>
    )
  }

  handleInfo = (text) => {          // handle display of information content from DataGridBase
    this.setState({ infoIsOpen: true, infoText: text })
  }

  //handles setting of new page (pagination)
  handleChangePage = (e, newPage) => this.setState({ page: newPage });

  //handles setting of new numver of rows per page (pagination)
  handleChangeRowsPerPage = event => this.setState({ rowsPerPage: +event.target.value, page: 0 });

  handleDelete = (fileName, Sender) => {
    if (Sender === this.props.user)  //Success case .User is file uploader
    {
      this.setState({ dialogIsOpen: true, fileRef: fileName })
    }
    else {
      this.props.sendAlert("Only the uploader can delete the file.", "ErrorAlert", 2000)
    }
  }

  // "cleans" the data into a format that can be fed into the dynamic table . Makes use of regex function to split the FileKey string into respective fields
  createData = () => {
    this.setState({ isReady: false })
    if (this.props.fileList == null) return;
    var rowsarr = []
    this.props.fileList.map((file, index) => {
      var fileName = file.fileKey;

      if (file.senderusername !== this.props.user) { return };
      if (fileName != null) {

        let FileName = trimFileName(fileName);
        let FullName = fileName;
        // let UploadDate = file.recipientlist[0].created_at;
        let UploadDate = file.dpfilestatusuploaddate ;
        UploadDate = moment.utc(UploadDate).local()
        let UploadTime = moment(UploadDate, 'MMMM Do YYYY, HH:mm:ss a').valueOf();
        //console.log(UploadTime)
        //let UploadTime = moment(UploadDate).format('MMMM Do YYYY, HH:mm:ss a');
        //use moment library to handle date and time formatting
        let ID = index + 1;
        let Category = determineFileExt(fileName);
        let Type = determineFileType(fileName);
        let Sender = file.senderusername;
        let ReceiverArray = [];
        let filestatusid = file.dpfilestatusid;
        let Receiver = "";
        let TotalDownloads = file.totaldownloads;
        if (TotalDownloads == null) TotalDownloads = 0
        for (var i = 0; i < file.recipientlist.length; i++) {
          if (file.recipientlist[i].ssogroupname !== null) {
            if (ReceiverArray.indexOf(file.recipientlist[i].ssogroupname) === -1) {
              if (i > 0) Receiver = Receiver + ", "
              ReceiverArray.push(file.recipientlist[i].ssogroupname)
              Receiver = Receiver + file.recipientlist[i].ssogroupname.toString();
              // if(i<file.recipientlist.length-1)Receiver=Receiver+", "
            }
          }
          else {
            if (i > 0) Receiver = Receiver + ", "
            ReceiverArray.push(file.recipientlist[i].recipientusername)
            Receiver = Receiver + file.recipientlist[i].recipientusername.toString();
            // if(i<file.recipientlist.length-1)Receiver=Receiver+", "
          }

          // if(i!=file.recipientlist.length-1)Receiver=Receiver+", "
        }
        rowsarr.push({ ID, FullName, FileName, UploadTime, Category, Type, Sender, Receiver, ReceiverArray, TotalDownloads, filestatusid })
      }
    })
    this.setState({ rows: rowsarr })
    setTimeout(() => this.setState({ isReady: true }), 1000)
  }


  //#region DB
  //gets signedurl from server to allow larger file downloads hands over url to triggerDownloadFileCont
  triggerDownloadFileLink = (fileName, trimmedFileName) => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}/api/excel/download?fileKey=${fileName}`,
      method: 'GET',
      responseType: 'text', // important
    }).then((response) => {
      this.triggerDownloadFile(response.data, trimmedFileName)
    });
  }

  //actually download the file from the S3 signed url returned above
  triggerDownloadFile = (signedurl, trimmedFileName) => {
    axios({
      url: signedurl,
      method: 'GET',
      responseType: 'blob', // important
      onDownloadProgress: ProgressEvent => {
        let arr = this.state.loaded;
        arr = (ProgressEvent.loaded / (ProgressEvent.total) * 100);
        this.setState({
          loaded: arr
        })
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', trimmedFileName);
      document.body.appendChild(link);
      link.click();
    }).then(() => { this.setState({ loaded: 0 }) });
  }

  //delete file from S3
  triggerDeleteFile = (fileName) => {
    // this.props.showLoading(true);
    axios.delete(`${process.env.REACT_APP_BASE_URL}/api/fileStatus/deleteFileKeySharing?fileKey=${fileName}`)
      .then(res => {
        // this.props.showLoading(false);
        this.props.triggerUpdate();
        this.props.sendAlert("File has been deleted", "SuccessAlert", 2000)
      }).then(setTimeout(this.createData, 2000))
      .catch(err => console.log(err));
    // this.props.showLoading(true);
  }
  //#endregion

  
  //#region editrec

  handleRecipient = (rec,data) => {   // handle display of recipients from DataGridBase//handle  //data is all values stored in selected row of reactdata grid
    //console.log(data)
    var index = this.props.fileList.map(e => e.fileKey).indexOf(data.FullName) //we cross reference the filekey(named fullname) in data grid against the file list pulled to get the index of the file. we store this index for easy access later

    this.setState({ recipientIsOpen: true, selectedRowRecipient: rec , recipients: rec ,selectedRow :index})
    // if(this.state.initRecipients<1)this.setState({initRecipients:rec})
  }

  setRecipients(rec) {  // handle display of recipients to friendlist
    this.setState({ recipients: rec })
  }

  showRecipient = () => //  handle opening of edit recipient dialog box
  {
    
    return (

      <Dialog

        maxWidth={'xs'}
        fullWidth={true}
        open={this.state.recipientIsOpen}
        onClose={this.handleRecipientClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit recipients"}</DialogTitle>
        <DialogContent>
          {/* {<Contacts contactList={this.props.contactList}></Contacts>} */}
          {this.state.selectedRowRecipient.length >= 0 &&
            <FriendList groupList={this.props.groupList} contactList={this.props.contactList} originalRecipients={this.state.selectedRowRecipient} 
            users={this.props.contactList} setRecipients={this.setRecipients.bind(this)} setFullRecipientList={this.props.setFullRecipientList.bind(this)} />}
        </DialogContent>
        <DialogActions>
          {/* <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleCardClose(e,false)} >
        VIEW MORE Information
      </Button> */}
          <Button contained={true} color="secondary" for="input" type="submit" onClick={(e) => this.handleRecipientDone(e, true)} autoFocus>
            DONE
      </Button>
        </DialogActions>
      </Dialog>
    )
  }
    
  handleRecipientClose = async () => // handle closing of edit recipient dialog box BY CLICKING ELSEWHERE
  {
    this.setState({ recipientIsOpen: false,selectedRowRecipient:[],recipients:[] })

  }


  handleRecipientDone = async () => // handle closing of edit recipient dialog box BY PRESSING DONE
  {

    this.setState({ recipientIsOpen: false })
    if (this.state.selectedRecipients)
    // await this.stripInitiGroupUsers();
    // await this.stripSelectedGroupUsers();
    await this.finalRecipients();
  }
  


  finalRecipients = async () => {  // handles the processing and sorting for editing of recipioents - output to 'recipientListForDelete' and 'recipientListForAdd'  (user to user, user to group, user to other users)
  let initArr = this.state.selectedRowRecipient
  let selectedArr = this.state.recipients


  var addArray=selectedArr.filter(n => !initArr.includes(n))
  var delArray=initArr.filter(n => !selectedArr.includes(n))
  var editedFile= this.props.fileList[[this.state.selectedRow]];
 
  this.setState({recipientListForAdd : this.stripAddGroupUsers(addArray), recipientListForDelete : this.stripDeletedGroupUsers(delArray)},
  function() 
    {
      if((this.state.recipientListForDelete.length > 0 || this.state.recipientListForAdd.length > 0)&& selectedArr.length>0) this.editDeleteRecipientList(editedFile.UUID,editedFile.fileKey,editedFile.dpfilestatusid)
      else 
      {
        this.setState({ recipientListForAdd:[],recipientListForDelete:[] ,selectedRowRecipient:[],recipients:[]})
        this.props.sendAlert("At least one recipient is required!", "WarningAlert", 4000)
      }
    }
  )
  
}

  editDeleteRecipientList = (UUID, FileKey, dpFileStatusID) => //handle api call to endpoints for edit recipients
  {
    // console.log(UUID); console.log(FileKey) ;console.log(dpFileStatusID);
    if (this.state.recipientListForDelete.length > 0) {
      axios.patch(`${process.env.REACT_APP_BASE_URL}/api/fileList/editrecipient`, [
        {
          dpfilestatusuuid: UUID,
          senderusername: this.props.user,
          dpfilestatusfilekey: FileKey,
          dpfilestatusid: dpFileStatusID,
          recipientlist: this.state.recipientListForDelete
        }
      ]).then(res => {
        // console.log(res)
        this.editAddRecipientList(UUID, FileKey, dpFileStatusID)
      })
    }

    else { this.editAddRecipientList(UUID, FileKey, dpFileStatusID) }
  }


  editAddRecipientList = (UUID, FileKey, dpFileStatusID, ) => {
    if (this.state.recipientListForAdd.length > 0) {
      axios.patch(`${process.env.REACT_APP_BASE_URL}/api/fileList/editrecipient`, [

        {
          dpfilestatusuuid: UUID,
          senderusername: this.props.user,
          dpfilestatusfilekey: FileKey,
          dpfilestatusid: dpFileStatusID,
          recipientlist: this.state.recipientListForAdd
        }
      ]).then(res => {
        // console.log(res)
        this.successfullyEditedRecipient();
      })
    }
    else {  this.successfullyEditedRecipient() }
    
    
  }
//#endregion

 successfullyEditedRecipient=()=>
 {
  this.props.sendAlert("Recipient edited successfully!","SuccessAlert",2000);
  setTimeout(() => this.setState({ selectedRow: null ,initRecipients:[]}), 1000); //clear selectedfile
  //setTimeout(() => this.props.setTab(-1), 2000); //clear selectedfile
  setTimeout(() =>window.location.reload(false), 2000); //clear selectedfile

 }


  showDialog() // handle opening of delete dialog box
  {
    return (
      <Dialog
        open={this.state.dialogIsOpen}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Selected File?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button contained={true} color="secondary" for="input" type="submit" onClick={(e) => this.handleClose(e, false)} >
            CANCEL
          </Button>
          <Button contained={true} color="secondary" for="input" type="submit" onClick={(e) => this.handleClose(e, true)} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleClose = (e, del) => // handle closing of delete dialog box
  {
    this.setState({ dialogIsOpen: false })
    if (del) {
      this.triggerDeleteFile(this.state.fileRef) //calls the api to delete the file from aws
      //console.log(this.state.fileRef)
    }
    else;
    setTimeout(() => this.setState({ fileRef: null }), 1000);
  }

  handleInfoClose = () => // handle closing of information dialog box
  {
    this.setState({ infoIsOpen: false })

  }

  showInfo = () => // handle opening of information dialog box
  {
    return (
      <Dialog
        maxWidth={'xs'}
        fullWidth={true}
        open={this.state.infoIsOpen}
        onClose={this.handleInfoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"File Information"}</DialogTitle>
        <DialogContent>
          {this.state.infoText}
        </DialogContent>
        <DialogActions>
          {/* <Button contained ={true} color ="secondary" for="input" type="submit" onClick={(e)=>this.handleCardClose(e,false)} >
        VIEW MORE Information
      </Button> */}
          <Button contained={true} color="secondary" for="input" type="submit" onClick={(e) => this.handleInfoClose(e, true)} autoFocus>
            DONE
      </Button>
        </DialogActions>
      </Dialog>
    )
  }

  stripInitiGroupUsers() {  // stripe groups to individual emails for currently tagged recipients
    var initRecipients = []
    var groupList = []
    this.state.selectedRowRecipient.map((rec, index) => {

      var groupIndex = this.props.groupList.findIndex(x => x.groupname === rec)
      if (groupIndex > -1) groupList.push(this.props.groupList[groupIndex])  //should refactor this to not copy in future
      else {
        var obj = { "recipientusername": rec }
        initRecipients.push(obj)
      }
      
    })

  if (groupList.length > 0) {
      groupList.map((grp, index) => {
        grp.usergroup.map((groupuser, index) => {
          var user = {};
          user.recipientusername = groupuser.username;
          user.ssogroupid = groupuser.groupid;
          user.ssogroupname = grp.groupname;

          initRecipients.push(user);
        })
      })
    }
    if(initRecipients.length>0)this.setState({ initRecipients:initRecipients,selectedRecipients:initRecipients })
    return 1;
  }

  stripSelectedGroupUsers() // strip group to user for selected recipeients
  {

    var selectedRecipients = []
    var groupList = []
    this.state.recipients.map((rec, index) => {

      var groupIndex = this.props.groupList.findIndex(x => x.groupname === rec)
      if (groupIndex > -1) groupList.push(this.props.groupList[groupIndex])  //should refactor this to not copy in future
      else {
        var obj = { "recipientusername": rec }
        selectedRecipients.push(obj)
      }
    })


    if (groupList.length > 0) {
      groupList.map((grp, index) => {
        grp.usergroup.map((groupuser, index) => {
          var user = {};
          user.recipientusername = groupuser.username;
          user.ssogroupid = groupuser.groupid;
          user.ssogroupname = grp.groupname;

          selectedRecipients.push(user);
        })
      })
    }

    if(selectedRecipients.length>0)this.setState({ selectedRecipients: selectedRecipients })
    return 1;
  }


  stripAddGroupUsers(addArray) {  // strip groups to individual emails for users to be added
    var initRecipients = []
    var groupList = []
    addArray.map((rec, index) => {

      var groupIndex = this.props.groupList.findIndex(x => x.groupname === rec)
      if (groupIndex > -1) groupList.push(this.props.groupList[groupIndex])  //should refactor this to not copy in future
      else {
        var obj = { "recipientusername": rec }
        initRecipients.push(obj)
      }
      
    })

  if (groupList.length > 0) {
      groupList.map((grp, index) => {
        grp.usergroup.map((groupuser, index) => {
          var user = {};
          user.recipientusername = groupuser.username;
          user.ssogroupid = groupuser.groupid;
          user.ssogroupname = grp.groupname;

          initRecipients.push(user);
        })
      })
    }
    if(initRecipients.length>0) return initRecipients
    else return []; 
  }

  stripDeletedGroupUsers(delArray) {  // strip groups to individual emails for users to be deleted
    var initRecipients = []
    var groupList = []
    delArray.map((rec, index) => {

      var groupIndex = this.props.groupList.findIndex(x => x.groupname === rec)
      if (groupIndex > -1) groupList.push(this.props.groupList[groupIndex])  //should refactor this to not copy in future
      else {
        var obj = { "recipientusername": rec, "delete":1 } //we add delete: 1 to tell api to delete
        initRecipients.push(obj)
      }
      
    })

  if (groupList.length > 0) {
      groupList.map((grp, index) => {
        grp.usergroup.map((groupuser, index) => {
          var user = {};
          user.recipientusername = groupuser.username;
          user.ssogroupid = groupuser.groupid;
          user.ssogroupname = grp.groupname;
          user.delete=1; //we add delete: 1 to tell api to delete

          initRecipients.push(user);
        })
      })
    }
    if(initRecipients.length>0) return initRecipients
    else return []; 
  }




 
}
export default MyUploads; 
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton } from 'horizon-components-react'
// import DataGrid from 'react-data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import axios from 'axios';
import EditIcon from '@material-ui/icons/Create';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import TextField from '@material-ui/core/TextField';
import AddUser from './addUser';
import 'react-data-grid/dist/react-data-grid.css';
import "./styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './MuiTableCell.css';
import DataGridBase from "./DataGridBase"
import {
  Backdrop, CircularProgress
} from "@material-ui/core";

const styles = (theme) => ({

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    transition: "ease-in 200ms",
  },
});

// require('dotenv').config();

//const theme = createMuiTheme({
//  typography: {
//    fontFamily: [
//     'Arial',
//      'Nunito',
//      'Roboto',
//      '"Helvetica Neue"',
//      'sans-serif'
//    ].join(','),
//  }
//});

// const styles = theme => ({
//   root: {
//     width: "80%",
//     backgroundColor: "#bdbdbd",
//     color: "#fafafa",
//     dragable: false,
//     borderRadius: "4px",
//     border: "1.5px solid #304ffe",
//     textAlign: "center",
//     textAlignLast: "center",

//   },

//   icon: {
//     width: 18,
//     height: 18,
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   chip: {
//     margin: 2,
//   }

// });


//const deleteFormatter = ({ value }) => {
//  console.log(value)
//  return(
//  <Button variant="primary" type="submit" style={{color: 'blue', fontWeight: 'bold', border: 'none'}} onClick={()=>console.log(value)}>
//    <DeleteIcon/>
//  </Button>
//  ) //To be converted into react-data-grid method later
//}

//.map(c => ({ ...c, ...defaultColumnProperties }));;;

class AccountManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      openDeleteDialog: false,
      deleteid: "",
      input: "",
      openErrorMessage: false,
      openSuccessMessage: false,
      existingApplicationRoles: [],
      applicationRoles: [],
      finalApplicationRoles: [],
      organizationData: [],
      organizationid: "",
      deleteUserApplicationRoles: [],
      applicationrolename: [],
      groupData: [],
      userGroupData: [],
      usersArray: [],
      addUserDialogOpen: false,
      isBackdrop:false

    }
  }

  //sleep(ms) {
  //  return new Promise(resolve => setTimeout(resolve, ms));
  //}

  handleOpenAddUserDialog = () => {

    this.setState({ addUserDialogOpen: true })
    // this.props.history.push('/admin/addUser')
  }

  handleCloseAddUserDialog = () => {

    this.setState({ addUserDialogOpen: false })
    // this.props.history.push('/admin/addUser')
  }

  componentDidMount() {
    this.getData();//get data for data grid
    axios.get(process.env.REACT_APP_SSO_API + `/organization`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    })
      .then(res => {
        console.log(res.data);
        this.setState({ organizationData: res.data })
      })
      .catch(err => {
        console.log(err);
      })//get data of organizations
  }

 getData=async()=> {

    var groupData = [];
    var usernames = "";
    await axios.get(process.env.REACT_APP_SSO_API + `/group`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    })
      .then(res => {
        console.log("Group Data",res.data);
        res.data.map((data, index) => {
          usernames = '';

          //const xx = []
          groupData.push({ groupid: data.groupid, groupname: data.groupname, organizationname: data.organizationname, applicationname: data.applicationname, private: data.groupisprivate })
          const userNames = data.usergroup.map(user=>user.username);
         // debugger
          // data.usergroup.map((user, id) => {

          //   if (id == 0) {
          //     usernames = user.username;
          //   }
          //   else {
          //     usernames = usernames + "," + user.username;


          //   }
          //   xx.push(user.username)
          // //  console.log(xx)
          // })
          groupData[index].users = userNames;
           

        })
      //   const usersArray = usernames.split(",");
      //  // console.log(usersArray)
     // usersArray:userNames/
        this.setState({ groupData: groupData })
      })
      .catch(err => {
        console.log(err);
      })
    //get data from API function, display on grid





    axios.get(process.env.REACT_APP_SSO_API + `/user`, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    })
      .then(res => {
        //console.log(res.data);
        var newData = [];
        var applicationRoleName = "";
        // var groupName = [];
        res.data.map((data, index) => { //map data from API
          let groupName = ''
            ///////////////////////////////////
          groupData.forEach((value)=>{
            if (value.users.includes(data.username)){
              groupName =value.groupname
            }
            else{
              groupName=''
            }
          })


 ///////////////////////////////////
            // if (groupData[0].users.includes(data.username)){
            //   groupName = groupData[0].groupname
            // }
            // else{
            //   groupName=''
            // }
          newData.push({ userid: data.userid, username: data.username, groupname:groupName,organizationname: data.organizationname })
          data.userapplicationrole.map((role, roleIndex) => { //map application role array from current index
            if (roleIndex === (data.userapplicationrole.length - 1)) {
              for (var i = 0; i < data.userapplicationrole.length; i++) {
                if (i == 0) {
                  applicationRoleName = data.userapplicationrole[i].applicationname + " (" + data.userapplicationrole[i].applicationrolename + ")";
                }
                else {
                  applicationRoleName = applicationRoleName + ", " + data.userapplicationrole[i].applicationname + " (" + data.userapplicationrole[i].applicationrolename + ")";
                }
              } //for first application role, set applicationRoleName to value at index 0, else if theres more than one, add into the string with ", " to seperate
             // console.log(applicationRoleName);
              newData[index].applicationrole = applicationRoleName;
            }
          })
        })
        this.setState({ rowData: newData });
        console.log(this.state.rowData)
        applicationRoleName = "";
      })
      .catch(err => {
        console.log(err);
      })


  }

  getCellActions = (column, row) => {
    if (column.key === 'delete') {
      return [
        {
          icon: <DeleteIcon style={{ width: "100" }} />,
          callback: () => { this.handleDeleteDialogOpen(row.userid) }
        }
      ];
    };
    if (column.key === 'edit') {
      return [
        {
          icon: <EditIcon style={{ width: "100" }} />,
          callback: () => { this.handleEditDialogOpen(row.userid, row.organizationid) }
        }
      ];
    }
  };//react data grid edit and delete

  handleErrorClose = () => {
    this.setState({ openErrorMessage: false });
  };

  handleSuccessClose = () => {
    this.setState({ openSuccessMessage: false });
  };

  // handleChange = (event) => {
  //   this.setState({ organizationid: event.target.value });
  //   console.log(event.target.value);
  // }; //handle change of select component


  handleDeleteDialogOpen = (id) => {
    this.setState({ deleteid: id })
    this.setState({ openDeleteDialog: true })
  };

  handleDeleteDialogClose = () => {
    this.setState({ deleteid: "" })
    this.setState({ openDeleteDialog: false })
  };

  handleDeleteDialogConfirm = () => {
    this.setState({isBackdrop:true})
    axios.delete(process.env.REACT_APP_SSO_API + `/user/` + this.state.deleteid, {
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY
      }
    })
      .then(res => {
        console.log("Deleted application row of id " + this.state.deleteid);
        this.getData();
        this.setState({isBackdrop:false, openDeleteDialog: false })
        this.props.sendAlert("User Deleted Successfully!","SuccessAlert",2000);
      })
      .catch(err => {
        console.log(err);
      })//function to delete when user confirms in delete dialog
  };

  render() {
    const { path, url } = this.props.match;
    console.log(path)
    const { classes } = this.props;

    const defaultColumnProperties = {
      resizable: true,
    };
    const column = [
      {
        key: 'userid', name: 'User ID', sortDescendingFirst: true, sortable: true, filterable: true,
        resizable: true,
      },
      {
        key: 'username', name: 'User Name', sortable: true, filterable: true,
        resizable: true,
      },
      {
        key: 'organizationname', name: 'Organization Name', sortable: true, filterable: true,
      },
      {
        key: 'groupname', name: 'Group Name', sortable: true, filterable: true,
        resizable: true,
      },
      {
        key: 'applicationrole', name: 'Application(s) & Role', sortable: true, filterable: true,
        resizable: true,
      },
      {
        key: 'delete', width: 100, name: 'Delete', sortable: false, filterable: false, resizable: true

        //formatter: deleteFormatter
        //formatter: CustomSimpleCellFormatter,
      },
      // { key: 'edit', name: 'Edit', width: 150, }
    ].map(c => ({ ...c, ...defaultColumnProperties }));;

    return (
      <div>
        <h3>User Management</h3>

        <Box
          display="flex"
          // width="80%"
          justifyContent="flex-end"
          m={1}>
          <Button contained={true} color="secondary" onClick={this.handleOpenAddUserDialog} style={{ fontWeight: 'bold' }}
          >
            <AddIcon />
            Add Users
          </Button>
          <Dialog open={this.state.addUserDialogOpen}
          ><AddUser sendAlert ={this.props.sendAlert} myUsername={this.props.myUsername} getData={this.getData} handleCloseAddUserDialog={this.handleCloseAddUserDialog} handleOpenAddUserDialog={this.handleOpenAddUserDialog} /></Dialog>

<Backdrop  open={this.state.isBackdrop}>
             
             <CircularProgress style={{ color: "#FFF" }} /> 
          <Dialog
            open={this.state.openDeleteDialog}
            onClose={this.handleDeleteDialogClose}>
            <DialogTitle id="delete-dialog-title">{"Delete Confirmation"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                You are about to delete. Are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDeleteDialogClose} color="default">
                Cancel
              </Button>
              <Button onClick={this.handleDeleteDialogConfirm} primary="true" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          </Backdrop>
        </Box>
    
        <DataGridBase

          // minHeight={350}
          handleDeleteDialogOpen={this.handleDeleteDialogOpen.bind(this)}
          columns={column}
          intialRows={this.state.rowData}
        // rowGetter={i => this.state.rowData[i]}
        // rowsCount={this.state.rowData.length}

        />
      
      </div>

    )
  }
}

export default withRouter(withStyles(styles)(AccountManagement));
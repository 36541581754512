import React, { Component } from 'react';
import axios from "axios";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination,Paper,Typography} from '@material-ui/core'
import{Stepper,Step,StepLabel,StepContent} from '@material-ui/core'
import {Button,IconButton} from 'horizon-components-react'
import {CssBaseline,ThemeProvider} from 'horizon-components-react';
import{Delete,Refresh}  from '@material-ui/icons/';
import FriendList from '../../components/FriendList.js';
import{determineFileExt} from '../../components/regex.js';
import {Progress} from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import'./MuiTableCell.css';
import XLSX from 'xlsx';



const styles = theme => ({
    root: {
        width: '100%',
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
    });

class UploadData extends Component
{
    state = {
        page:0, //current page for pagination
        rowsPerPage:10, //total rows to display per page
        rows:[], //content per row
        recipients:[], //
        loaded: 0,
        selectedFiles:[],
        activeStep:0,
        dat:null
    }

    //handles submitting upload requestes to database. is an ASYNC function, so that multiple files can be placed in a queue
    handleSubmit= async (e)=>
    {
        e.preventDefault();
        let promises=[];
        this.props.showLoading(true)
        this.state.selectedFiles.map((file,index)=>{
            promises.push(this.sendDataToServer(file,index))
        });
        Promise.all(promises)
            .then(()=>{
                this.setState({selectedFiles:[],loaded:0,recipients:[]})
                //console.log("all files sent!")
                this.props.showLoading(false)
            })
    }

    //#region DB   //MAYBE SHOULD EXTRACT THIS TO A FUCTIONAL COMPONENT
    sendDataToServer = (file,index)=>{
        return new Promise((resolve,reject)=>{
            let fileKey;
            let url;
            var self = this;
        
            //console.log(process.env.REACT_APP_BASE_URL+'/api/fileStatus/addFileKeySharing')    
            //insert into status database
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/fileStatus/addFileKeySharing`,{
                USER_ID: this.props.user.userid,
                Company_ID : this.props.user.companyid,
                companyName : this.props.user.company,
                fileCat : "ARC", //hardcode this for now
                fileExt : file.fileExt,
                fileName : file.fileName,
                sender:this.props.user.username,
                recipient:this.state.recipients

            })
            // get signed url
                .then(res=>{
                    
                    fileKey = res.data;
                    //console.log(fileKey)
                    
                    return axios.get(`${process.env.REACT_APP_PUT_SIGNED_URL}`,{
                        params:{
                            fileKey
                        }
                    })
                })
                // set file to s3
                .then(res => {
                    
                    let signedUrl = res.data;
                    //console.log(signedUrl);
                  
                    return axios({
                        method: 'PUT',
                        url : signedUrl,
                        data: file.file, //we access the full file read in
                        // withCredentials: true,
                        // crossdomain: true,
                        config: { headers: {
                            'Content-Type':  'multipart/form-data',
                            'Content-Encoding': 'base64'
                        }},
                        onUploadProgress: ProgressEvent => {
                            let arr = self.state.loaded;
                            arr = (ProgressEvent.loaded /(ProgressEvent.total)*100);
                            self.setState({
                                loaded : arr
                            }) }
                    })
                })
                // // change the acl of the file to be public
                .then(response => {
                    url = response.config.url;
                    url = url.substring(0,url.indexOf('?'));

                    return "done";
                })
                //insert fileKey and SheetName into data
                .then(res=>{ 
                    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/addSheetName`,{
                        fileKey,
                        sheetName: file.sheetName
                    })
                })
                // add the url and success upload not
                .then(res=>{
                    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/fileStatus/S3`,{
                        fileKey,
                        url
                    })
                })
                // redirect to index
                .then((res)=>{
                    // redirect to index page
                    this.props.sendAlert("Your files have been uploaded successfully!","SuccessAlert",2000);
                    this.props.triggerUpdate();
                    this.handleStep("handleNext");
                    resolve();
                })
                .catch(err=>reject(err.response));
        })
    }
    //#endregion

    componentDidUpdate(prevProps,prevState)
    {
        if(this.state.selectedFiles!==prevState.selectedFiles)  this.createData(); //if selectedFileData has been updated, update "rows " value to update UI
    }

    //handles change in "fileinput " info
    onChangeHandler = (e) => {
        var filesArr=Array.from(e.target.files)
        var file =filesArr[0]
        this.processExcel(file);
        //var workbook = XLSX.readFile(file.name, {type:'binary'});
        console.log(e.target)


        //extract here
        //this.filestoBase64(filesArr).then((result)=>{this.setState({selectedFiles:result})}).then((res)=>{this.createData();})
      }

      processExcel(file)
      {
        var name = file.name;
        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, {header:1});
        /* Update state */

        var stringArray = data.split(/[\r\n]+/);
        
        console.log("Data>>>"+stringArray);
        this.setState({dat:stringArray})
        };
        reader.readAsBinaryString(file);
      }

      //converts file to base64 so we can store/send it (for some reason , direct storing e.target.files = null) 
      filestoBase64_1=async (files)=>
      {
        let promises = [];
        function getBase64(file) {
            const reader = new FileReader();
            let fileExt = determineFileExt(file.name);
            return new Promise((resolve,reject) => {
              reader.onload = event => {
                resolve({
                  base64String:event.target.result,
                  fileName : file.name,
                  fileExt : fileExt
                })
              }
              reader.readAsDataURL(file)
            })
          }
          files.map(file=>{
            promises.push(getBase64(file))
          });
          return await Promise.all(promises)
      }

      //NEW base64 conversion. reads file, converts to base64 string, then stores in a buffer int8 array to be sent to s3
      filestoBase64=async (files)=>
      {
        let promises = [];
        function getBase64(file) {
            //console.log(file)
            const reader = new FileReader();
            let fileExt = determineFileExt(file.name);
            return new Promise((resolve,reject) => {
              reader.onload = event => {
                let data = reader.result;
                let buffer = new Int8Array(data); //we need to store this in a buffer, else AWS receives and stores as a base64 array object
                resolve({
                    buffer: buffer,
                    fileName : file.name,
                    fileExt : fileExt,
                    file
                })
              }
              reader.readAsArrayBuffer(file)
            })
          }


          files.map(file=>{
            promises.push(getBase64(file))
          });
          return await Promise.all(promises)
      }

    // "cleans" the data into a format that can be fed into the dynamic table  //also removes array data when removeFile is called
    createData=() =>{
        var rowsarr=[]
        if(this.state.selectedFiles!=null)
        {
            this.state.selectedFiles.map((file,index) => {
                if(file.fileName != null){
                    let ID =index+1;
                    let FileName  = file.fileName;
                    let Category=file.fileExt;
                    rowsarr.push({ID,FileName,Category})  
                }
            })
        }
        this.setState({rows:rowsarr})
    }

        //handles setting of new page (pagination)
        handleChangePage = (e,newPage) =>this.setState({page:newPage});

        //handles setting of new numver of rows per page (pagination)
        handleChangeRowsPerPage = event => this.setState({rowsPerPage:+event.target.value ,page:0});


    render()
    {
        const {classes} = this.props;
        const steps=this.getSteps();

        return(
            <div className={classes.root}>
                <h2 style={{paddingLeft:'1rem'}}> Transfer Files / Data </h2>
                <Stepper activeStep={this.state.activeStep} orientation="vertical">
                    
                    <Step key={steps[0]} /*Division for selecting files*/>
                        <StepLabel>{steps[0]}</StepLabel>
                        <StepContent TransitionComponent="None">
                            {this.generateTable()}
                            <Button contained ={true} color ="secondary" for="input" type="submit" onClick={()=>{document.getElementById("input").click()}} style={{fontWeight: 'bold'}} 
                            disabled={(this.state.activeStep!==0)}>
                                Choose Files
                                </Button>
                            <input type="file" accept=".csv,.xlsx,.xlsm" class="form-control" id="input" hidden multiple onChange={this.onChangeHandler} />
                                <div className={classes.actionsContainer}>
                                    <div>
               
                                    <Button
                                        disabled={!this.state.rows.length >0||(this.state.activeStep!==0)}
                                        variant="contained"
                                        color="primary"
                                        onClick={()=>this.handleStep("handleNext")}
                                        className={classes.button}
                                    >   Next
                                        {/* {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next' /* this was for dynamic looping of content, we can remove this and replace with just the required text actually */} 
                                    </Button>
                                    </div>
                                </div>
                        </StepContent>
                    </Step>
                    
                    <Step key={steps[1]} active={(this.state.activeStep>0)} /*Division for selecting recipients*/>
                        <StepLabel>{steps[1]}</StepLabel>
                        <StepContent T>

                        <FriendList users={this.props.contactList} setRecipients={this.setRecipients.bind(this)} recipients={this.state.recipients}  />
                            <div className={classes.actionsContainer}>
                                <div>
                                <Button
                                    disabled={this.state.activeStep !== 1}
                                    onClick={()=>this.handleStep("handleBack")}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    disabled={!this.state.recipients.length>0||(this.state.activeStep!==1)}
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>this.handleStep("handleNext")}
                                    className={classes.button}
                                >
                                    Next
                                    {/* {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                                </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>

                    <Step key={steps[2]} /*Division for selecting upload*/>
                        <StepLabel>{steps[2]}</StepLabel>
                        <StepContent>
                        {this.state.loaded>0 &&
                        <Progress max="100" color="info" value={this.state.loaded>0? Math.round(this.state.loaded):0}
                        className="text-center"
                        >{this.state.loaded>0?Math.round(this.state.loaded):0}%</Progress>
                        }
                        
                        <Button contained ={true} color= "primary" disabled ={this.isDisableUploadButton()} type="submit" onClick={this.handleSubmit} style={{ fontWeight: 'bold', marginLeft: '1rem'}} >Upload</Button>
                                <div className={classes.actionsContainer}>
                                    <div>
                                    <Button
                                        disabled={this.state.activeStep === 0}
                                        onClick={()=>this.handleStep("handleBack")}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    </div>
                                </div>
                        </StepContent>
                    </Step>
                   
                </Stepper>
                {this.state.activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={()=>this.handleStep("handleReset")} className={classes.button} color="primary">
                        Upload more files
                    </Button>
                    </Paper>
                )}
            </div>
        )
    }

    //checks if there is a recipient and a file to send, returns inverse value as it is used in "disabled" field
    //always use .length when checking against array to avoid wrong return booleans
    isDisableUploadButton=()=>
    {
        if(this.state.recipients.length>0 && this.state.rows.length >0) return false;
        else return true;
    }
    

    //remove file from list (both selectedFiles, and calls createData(due to state update) to remove value from "rows" as well)
    RemoveFile(ID)
    {
        var remove =ID-1
        var tempArr=this.state.selectedFiles.slice();
        tempArr.splice(remove,1)
        this.setState({selectedFiles:tempArr})
    }

    //callback to be passed to "FriendsList.js" to update recipients info
    setRecipients(rec) { 
        this.setState({recipients:rec}) 
    } 

     //#region table
    //define columns of table
    columns=[
        {id:'ID',label:'#',align:'left',minWidth:'1rem',fontSize:'1rem'},/* incrementing number of item in list */
        {id:'FileName',label:'File Name',align:'left',minWidth:'10rem',fontSize:'1rem'},/* file name */
    ]

    //handles the generation of the table data from the columns defined above , returns as html for render component
    generateTable()
    {
        return(
            <ThemeProvider theme="airbus">
            <CssBaseline />
            <Paper style={{width:'100%'}}>
            
                <TableContainer style={{maxHeight: 800}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {this.columns.map(column => (
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth ,fontSize:column.fontSize}}
                                        >
                                        <b>{column.label}</b>
                                        </TableCell>
                                    ))}
                                    <TableCell key="deleteIcon" align="left" style={{ minWidth: "2rem" ,fontSize:"1rem"}}>   <b>Delete </b> </TableCell>
                                    {/* <TableCell key="refreshIcon" align="right" style={{ minWidth: "2rem" ,fontSize:"2rem"}}  >  
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {this.state.rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(row => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
                                        {this.columns.map(column => {
                                            // if(column.id==="Recipient") return;
                                            // console.log(column.id)
                                            const value = row[column.id];
                                            return (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: "8rem" ,fontSize:"1rem"}}>
                                                {value}
                                            </TableCell>
                                            );
                                        })}
                                            <TableCell key="delete" align="left" style={{ minWidth: "2rem" ,fontSize:"1rem"}}>
                                            <IconButton contained ={true} color="primary"
                                                // onClick={()=>{this.RemoveFile(this.state.rows[row.ID].FileName)}}> 
                                                onClick={()=>{this.RemoveFile(row.ID)}}> 
                                                 <Delete />
                                                 </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            
                        </TableBody>
                    </Table>
                </TableContainer>    
                { <TablePagination 
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={this.state.rows.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                /> }
            </Paper>
            </ThemeProvider>
        )
    }

    //#endregion

    //#region Stepper

    //legacy code from original looping stepper method.Required unless step reference is removed in stepper above
    getSteps() {
        return ['Select files to upload', 'Select recipients', 'Upload files'];
    }

    //increments,decrements or resets current step as required
    handleStep=(type)=>
    {
        var stateVal;
        //console.log(type)
        switch(type)
        {
            case "handleNext": 
            stateVal=this.state.activeStep;
            stateVal++;
            this.setState({activeStep:stateVal});
            return;

            case "handleBack": 
            stateVal=this.state.activeStep;
            stateVal--;
            this.setState({activeStep:stateVal});
            return;

            case "handleReset": 
            this.setState({activeStep:0});
            return;

            default:return;
        }
    }

    //#endregion


}
export default withStyles(styles)(UploadData); 
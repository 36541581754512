import React, { Component } from 'react';
import {Menu, MenuItem} from '@material-ui/core';
import {BrowserRouter, Link, withRouter} from 'react-router-dom';
import{deleteCookie} from '../Cookie.js'
import { IconButton} from "horizon-components-react";
import Avatar from '@material-ui/core/Avatar';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
marginRight:"1.5rem"
    },
  },
}));

function SimpleMenu(props) {  // Dropdown list for account mgmt options at the side
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (e,index) => {
    setAnchorEl(null);
    switch(index)
    {
      case 0:
        //do profile link here
        return;
      case 1: 
      //do acc managment here

      props.history.push( "/"+props.role+"/accountManagement");
        return;
      case 2: 
      deleteCookie(["LoginToken","RoleToken"]);
      props.history.push( "/login");
        return;
        case 3: 
        props.history.push( "/"+props.role+"/help");
        return;
      default:
        return;
    }
  };
  
  return (
    
    <div className={classes.root}>
       
      <IconButton color ="white"  onClick = {handleClick}>
         {/* <Badge overlap="circle"  badgeContent={props.newFiles} color="error"> */}
       <Avatar  >
       <PermIdentityIcon style={{fontSize:'2rem'}} />
       </Avatar>  
       {/* </Badge>  */}
      </IconButton>
  
      <BrowserRouter> 
      <Menu
     id="simple-menu"     // manages dropdownlist actions here
     anchorEl={anchorEl}
     keepMounted
     open={Boolean(anchorEl)}
     onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}><Link style={{textDecoration: 'none'}}
                            to="/changePassword">Account Management</Link> </MenuItem> */}
        <MenuItem onClick={e=>handleClose(e,3)}>User Documentation</MenuItem>
        {props.role !=='user' &&<MenuItem onClick={e=>handleClose(e,1)}>Account Management</MenuItem>} 
        <MenuItem onClick={e=>handleClose(e,2)}>Logout</MenuItem>
    
   
      </Menu>
      </BrowserRouter> 
    </div>
  );
}

export default withRouter(SimpleMenu)
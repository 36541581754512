import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import clsx from "clsx";
import {Grid,Card,Typography,CardActions,CardMedia,CardActionArea } from '@material-ui/core';
import './style.css';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import queryString from 'query-string';

const styles = theme => ({
  root: {
    flexGrow: 1,
    // justifyContent: "center",
    // alignItems: "center",
  },
  card: {
    flexGrow: 1,
    minWidth: 250,
    minHeight: 250,
    maxWidth:250,
    maxHeight:250,
  },
  grid: {
    minWidth: 275,
    maxWidth:280,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:"75%",
  },
  });


class LandingPage extends React.Component
{
    state=
    {
      isExpanded:[false,false],

    }

    handleExpandClick=(e,index)=>{ 
      this.props.setTab(index);
        // if(index===0) this.props.history.push( "/"+this.props.role+"/uploadFile");
        // if(index===1) this.props.history.push( "/"+this.props.role+"/downloadFile");
        // if(index===2) this.props.history.push( "/"+this.props.role+"/contacts");
    }

    //sets drawer state. In this case it's an array to cater to multiple drawers with a single function
    toggleDrawer=(e,index)=>
    {
      //console.log(index)
      var expanded=this.state.isExpanded.slice();
      expanded[index]=!this.state.isExpanded[index];
      this.setState({isExpanded:expanded});
    }
  
checkFile=()=>

{
//this.props.checkNewFiles();

}


    render()
    {
        const {classes} = this.props;
        // const values = queryString.parse(window.location.href)
        // console.log(values)
        return(
          <div className={classes.root}>
            <h2 style={{marginLeft:'1rem'}}> FHS SFR File Upload  </h2> 
                {/* <Box pt={30} pl={50}></Box> */}
                <Box pt={5} pl={50}></Box>
            <Grid container spacing={2}  justify="flex-start">
              {/* <Grid item xs={1}/> */}
              <Grid item xs={0.5}/>
              <Grid item xs={5} className={classes.grid}  >
                <Card className={classes.card} >
                  <CardActionArea onClick={(e)=>this.handleExpandClick(e,0)} >
                  
                  <Grid container spacing={2}  justify="flex-start">
                  <Grid item xs={12}/>

                  <Grid item xs={12}>
                    <Typography style={{marginTop:"1.5rem"}}gutterBottom variant="h5" component="h2" align={"center"}>
                    <UploadIcon  fontSize="large" />  Upload
                    </Typography>
                  </Grid>
                  <Grid item xs={12}/>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" component="p" align={"center"}  >
                       <strong>This tab allows you to upload PDFs, for SFR Textract processing </strong>
                    </Typography>
                    </Grid>
                    
                  </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
              {/* <Grid item xs={5}/> */}
               <Grid item xs={0.5}/>
              <Grid item xs={5} className={classes.grid} >
                <Card className={classes.card} >
                  <CardActionArea onClick={(e)=>this.handleExpandClick(e,1)}>

                  <Grid container spacing={2}  justify="flex-start">
                  <Grid item xs={12}/>

                  <Grid item xs={12}>
                  {/* <Badge style={{paddingLeft:'13rem'}} badgeContent={this.props.newFiles} //set badge number from parent
                   invisible={this.props.newFiles<=0} color="error">
      </Badge> */}
                  <Typography style={{marginTop:"1.5rem"}} gutterBottom variant="h5" component="h2" align={"center"} >
                    <DownloadIcon fontSize="large" />  File Repository
                    </Typography>
                  </Grid>
                  <Grid item xs={12}/>
                  <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p" align={"center"}>
                      <strong>This tab allows you to download PDFs uploaded by other FHS users</strong>
                    </Typography>
                    </Grid>
                    
                  </Grid>

                  </CardActionArea>
                </Card>
              </Grid> 
              {/* <Grid item xs={5}/> */}
            </Grid>
          </div>

        )
    }
}
export default withRouter(withStyles(styles)(LandingPage));
import React from 'react';
import SimpleMenu from './Menu';
import SimpleCard from './Card';
import { makeStyles } from '@material-ui/core/styles';
import "./style.css";
import{Typography} from "@material-ui/core"
import {
    AppBar,
    AppTitle,
    ThemeProvider,
    Toolbar,
  } from 'horizon-components-react';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
 
  }));
   
  export default function Header(props) {     //main header component to be called to be rendered
    const classes = useStyles();
  
    return(
        <div className={classes.root}>

     <ThemeProvider theme= {"airbus"}>

     <AppBar sticky>
    
         <Toolbar disableGutters>
         <SimpleCard role={props.role} setTab={props.setTab} ></SimpleCard>   
         
     
 
             <AppTitle style = {{flexGrow:1}}>  
              
              <div className ="text1"> FLIGHT HOUR SERVICES (FHS) </div>
              {/* <div className ="text1"> AIRFRAME SERVICES {"("+process.env.NODE_ENV.toUpperCase()+ ")"}</div> */}
              <div className ="text2" >SFR FILE UPLOAD</div>
              

          </AppTitle>
          {/* <div >
          <div className ="buildver"  >{process.env.REACT_APP_ENV.toUpperCase()} BUILD</div>
          </div> */}

          <div>                 
          <Typography>{props.username}</Typography>    
          </div>
                                     
          <div>                 
          <SimpleMenu role={props.role} newFiles = {props.newFiles}></SimpleMenu>    
          </div>
                                          
         </Toolbar>
        </AppBar>
    </ThemeProvider>
</div>

        );
    
}

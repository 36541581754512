import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import RenderPDF from './RenderPDF.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    // justifyContent: "center",
    // alignItems: "center",
  },
  card: {
    flexGrow: 1,
    minWidth: 250,
    minHeight: 250,
    maxWidth:250,
    maxHeight:250,
  },
  grid: {
    minWidth: 275,
    maxWidth:280,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:"75%",
  },
  });


class Help extends React.Component
{
    state=
    {
      isExpanded:[false,false],
    }

    handleExpandClick=(e,index)=>{ 
      this.props.setTab(index);
        // if(index===0) this.props.history.push( "/"+this.props.role+"/uploadFile");
        // if(index===1) this.props.history.push( "/"+this.props.role+"/downloadFile");
        // if(index===2) this.props.history.push( "/"+this.props.role+"/contacts");
    }

    //sets drawer state. In this case it's an array to cater to multiple drawers with a single function
    toggleDrawer=(e,index)=>
    {
      console.log(index)
      var expanded=this.state.isExpanded.slice();
      expanded[index]=!this.state.isExpanded[index];
      this.setState({isExpanded:expanded});
    }
  
checkFile=()=>

{
//this.props.checkNewFiles();

}


    render()
    {
        const {classes} = this.props;
      
        return(
          <div className={classes.root}>
            <h2 style={{marginLeft:'1rem'}}> User Documentation  </h2> 
            <RenderPDF/>    
          </div>

        )
    }

}
export default withRouter(withStyles(styles)(Help));
import React, { Component } from 'react';
import {NavLink, Redirect} from 'react-router-dom'
import './style.css';
import {Button} from 'horizon-components-react';
//import Spinner from "./spinner";


  class LoginPage extends Component{

    state={
        //spinnerStatus : false
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.history.push('/auth')
        //window.location.href = 'https://sso.airbus-hof-api.gq/login?success_callbackurl=http://localhost:3000&failure_callbackurl=http://localhost:3000&applicationname=FileSharing';
    }
    componentDidMount()
    {//console.log(this.props.location.pathname)
    }

    render(){
    return(

    

        <div class = "overlay" >
           <h1 style = {{marginTop: '1rem', color: "white", fontWeight: 'bold'}}> FHS</h1> 
    {/* <img style ={{marginTop: '1rem'}} width="15%" height="15%"   src="/images/airbus_logo_white_svg.svg" alt="react-logo"/> airbus logo */}
            <h2 style={{color: '#fbca33', fontWeight: 'bold'}} >SFR FIle Upload</h2>
            {/* <Form style={{color: 'white', fontWeight: 'bold'}} */}
            
            <Button variant="primary" type="submit" onClick={(e) => this.handleSubmit(e)} style={{color: 'white', fontWeight: 'bold'}} >
                Login
            </Button>
        </div>

 
        )}
}


export default LoginPage;
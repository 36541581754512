   //get the file cat using regex 
export const determineFileType=(fileName)=> {
    var first =fileName.indexOf('/', fileName.indexOf('/')+1) //index of second '/'
    var second=fileName.indexOf('/', first+1)//index of third '/'
    var myRe=fileName.substring(first+1, second);
    return (myRe);
}

//get the file extension using regex 
export const determineFileExt=(fileName)=> {
//regex that look for the ext of the file
var myRe = /\w+$/;
var ret=myRe.exec(fileName)
return (ret[0]);
}

//extract file name only from full location text
export const trimFileName = (fileName)=>{
    const lastIndex = fileName.lastIndexOf('/');
    return fileName.substring(lastIndex+1);
}
//extract date name only from full location text
export const trimDate = (fileName)=>{
    let lastIndex = fileName.lastIndexOf('/');
    const newName = fileName.substring(0,lastIndex);
    lastIndex = newName.lastIndexOf('/');
    let replacedFileName= newName.substring(lastIndex+1).replace("T"," ");
    let reformattedFileName= replacedFileName.replace("_",":");   // regex statement needs to be optimized in the future
    return reformattedFileName.replace("_",":");      //  regex statement needs to be optimized in the future


}

export const FileNameOnly = (fileName)=>{

    return fileName.replace(/\.[^/.]+$/, "")
}
 


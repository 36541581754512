import React, { Component } from 'react';
import './style.css';
import DataGridBase from '../components/DataGrid/DataGridBase.js'
import Box from '@material-ui/core/Box';
import {Dialog ,DialogActions, DialogContent, DialogContentText,DialogTitle} from'@material-ui/core';
import {Button, IconButton} from 'horizon-components-react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination,Paper,Typography,Tabs,Tab} from '@material-ui/core'
import { TabContainer } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import {Avatar ,List, ListItem, ListItemAvatar,ListItemText} from'@material-ui/core';
import PersonIcon from "@material-ui/icons/Person";

const styles = theme => ({
  root: {
      width: '100%',
    },
    usertab: {
      width: '100%',
    },
    grouptab: {
      width: '200%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    default_tabStyle:{
      fontSize:"0.80rem",
      color: 'white',
      backgroundColor: '#00205b',
      opacity: 1,
      textTransform:'capitalize'
     },
      active_tabStyle:{
      fontSize:"0.80rem",
      fontWeight:'bold',
      color: '#fbca33',
      backgroundColor: '#00205b',
      opacity: 1,
      textTransform:'capitalize',
     }
  });
  const UserFormatter = ({ value }) => {
    var Users=""

    for (var i=0;i<value.length;i++)
    {
      if(i>0)Users=Users+", "
      Users=Users+value[i].username;
    }
  
    return Users;
   };

   const OrgFormatter = ({ value }) => {
    var val=value[0].organizationname;
    return val;
   };

class Contacts extends Component
{


    state = {
      userList:[],
      groupUserList:[],
      activeTab:0,
      InfoIsOpen:false,
    }
 
    tabChange=(event, value, index)=>
    {
        this.setState({ activeTab:value });
    }

    handleUserList =(users)=>
    {
      this.setState({groupUserList:users,InfoIsOpen:true})
    }

    handleClose =()=>
    {
      this.setState({groupUserList:[],InfoIsOpen:false})
    }

    render()
    { 
      const {classes} = this.props;

        return(
          <div className={classes.root}>
                <h2> Contact List </h2>

                <Tabs  
                    value={this.state.activeTab}
                    onChange={this.tabChange}
                    TabIndicatorProps={{style: {backgroundColor: '#fbca33'}}}
                >
                    <Tab className= // Contacts tab
                    {this.state.activeTab===0 ? classes.active_tabStyle :classes.default_tabStyle}label= "Contacts" >
                    </Tab>
                
                    <Tab className=    // View upload tab
                    {this.state.activeTab===1 ? classes.active_tabStyle :classes.default_tabStyle}label= " Groups" >
                    </Tab>
                </Tabs>
                
                <div className={classes.user}>
                { // Upload Tab Content 
                    this.state.activeTab ===0 && 
                    <TabContainer><Box width="60%">
                    {<DataGridBase 
                    intialRows={this.props.contactList} 
                    columns={[
                        {
                        key: 'username',
                        name: 'User',
                        sortable: true,filterable: true,resizable: true,
                        },
                        {
                        key: 'organizationname',
                        name: 'Organization Name',
                        sortable: true,filterable: true,resizable: true,
                        },
                    ]}
                    />}
                    
                </Box>
                    </TabContainer>
                    
                }</div>
                <div className={classes.grouptab}>
                    { // Upload Tab Content 
                    this.state.activeTab ===1 && 
                    <TabContainer><Box width="30%">
                    {<DataGridBase 
                    intialRows={this.props.groupList} 
                    handleUserList={this.handleUserList.bind(this)} 
                    columns={[
                        {
                        key: 'groupname',
                        name: 'Group',
                        sortable: true,filterable: true,resizable: true,
                        },
                        {
                        key: 'organizationname',
                        name: 'Organization Name',
                        sortable: true,filterable: true,resizable: true,
                        //formatter:OrgFormatter
                        },
                        // {
                        //   key: 'usergroup',
                        //   name: 'Users',
                        //   sortable: false,filterable: false,resizable: true,
                        //   formatter:UserFormatter
                        // },
                        {
                          key: 'UserInfo',
                          name: 'Users',
                          sortable: false, filterable: false, resizable: true,
                          width: 100,
                        },                  
                    ]}
                    />}
                    
                </Box>
                    </TabContainer>
                }
                </div>
                <Dialog
                  onClose={this.handleClose}
                  aria-labelledby="simple-dialog-title"
                  open={this.state.InfoIsOpen}
                  >
                  <DialogTitle id="simple-dialog-title">Users</DialogTitle>
                  <List>
                    {this.state.groupUserList.map(user => (
                      <ListItem
                        //button
                        //onClick={() => handleListItemClick(email)}
                        key={user}
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.username} />
                      </ListItem>
                    ))}
                  </List>
                </Dialog>
                
            </div>
        )
    }

}

export default withStyles(styles)(Contacts); 
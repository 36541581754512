import React from 'react';
import {useState,useEffect}from "react";
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ContactIcon from '@material-ui/icons/Contacts';
import {Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, useTheme,Typography} from '@material-ui/core/';
import {withRouter, BrowserRouter} from "react-router-dom";
import Clockv1 from "../Clock.js"
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const drawerWidth = "9rem";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: "1.2rem",
    color: "white"
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerOpen: {
    marginLeft: -drawerWidth,
    background: '#00205b',
    width: drawerWidth,
    top: "5rem",   // theme.spacing.unit (2)
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerHeader: {       //header for drawer (menu & chervon)
    display: 'flex',
    paddingLeft:"4.5rem",
    alignItems: 'right',
    // padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  
  drawerClose: {
    marginLeft: '0px',
    minWidth:'4rem',
    display: 'flex',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#00205b',
    top: theme.spacing.unit * 10,
    overflowX: 'hidden',
    width: theme.spacing(1) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5.4) ,
      
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    
  },
  tabsIcon:
  {
    color:'white',

  },
  tabsText:
  {
    color:'white', 
    fontSize:'0.67em', 
    fontWeight: 'bold',
  },
  
  tabsIconSelected:
  {
    color:'#FBCA33', //from horizon theme color ...we should use theme context in the future

  },
  tabsTextSelected:
  {
    color:'#FBCA33',  //from horizon theme color ...we should use theme context in the future
    fontSize:'0.67em', 
    fontWeight: 'bold'
  },
  version:
  {

    align:"center",
    alignItems:"flex-end",
    color: 'white',
   
  },
  space:
  {

    align:"center",
    alignItems:"flex-end",
    color: 'white',
    marginTop:"36rem",
    bottom: "0",
    justifyContent: 'flex-end',
    // marginBottom: 36
  },





}));

function Sidebar(props) {   // set initial state for drawer

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const lastClicked=props.selectedTab
  //const [lastClicked,setLastClicked] = React.useState(props.selectedTab);

  const setSelectedTab=(index)=>
  {
    props.setTab(index);
  }
  
  const handleDrawerOpen = () => {
    setOpen(true);
    props.drawerState(true); //send drawer state to parent so we can scale content div
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.drawerState(false);//send drawer state to parent so we can scale content div
  };

  const onClickHandler=(e,index)=>{ //for sidebar options routing
        setSelectedTab(index);
        //MOVED TO PARENT
        // if(index==0) {props.history.push( "/"+role+"/uploadFile");}
        // if(index==1) {props.history.push(  "/"+role+"/downloadFile");}
        // if(index==2) {props.history.push(  "/"+role+"/contacts");}
}

const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to={"/"+props.role+"/changelog"} {...props} />
));

// useEffect(() => {
//     if(lastClicked!==props.selectedTab) setLastClicked(props.selectedTab);
// });


  return (
    <div className={classes.root}>
      <Drawer 
        variant="permanent"
        className={clsx(classes.drawer, {    //handle open and close states
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({   //handle open and close states
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
         {/* <div className={classes.drawerHeader}>
       {!open && <IconButton  // icon button for opening of drawer
             color="inherit"
             aria-label="open drawer"
             onClick={handleDrawerOpen}
             className={clsx(classes.menuButton, {
               [classes.hide]: open,
             })}
           >
             <MenuIcon  />
           </IconButton>}
          {open &&<IconButton  onClick={handleDrawerClose} // icon button for closing of drawer
          > 
            {<ChevronLeftIcon style={{color:'white'}}/>}
          </IconButton>}
        </div>  */}
        <BrowserRouter>
        <List >
          <ListItem  alignItems='center' id={"upload"} button key={'Upload File'} onClick= {e=>onClickHandler(e,0)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==0?classes.tabsIconSelected:classes.tabsIcon} ><UploadIcon /></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}}disableTypography = {true } className={lastClicked==0?classes.tabsTextSelected:classes.tabsText} primary={'Upload File'}/>}                                      
          </ListItem>
          <ListItem  id={'download'} alignItems="center" button key={'Download File'} onClick= {e=>onClickHandler(e,1)}>
            <ListItemIcon style={{minWidth:'25px'}}   className={lastClicked==1?classes.tabsIconSelected:classes.tabsIcon} ><DownloadIcon/></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"10px"}} textAlign='center' disableTypography = {true } className={lastClicked==1?classes.tabsTextSelected:classes.tabsText} primary={'File Repository'} location={props.location}/>}                                      
          </ListItem>
          {/* <ListItem  id={'contacts'} alignItems="center" button key={'Contact List'} onClick= {e=>onClickHandler(e,2)}>
            <ListItemIcon style={{minWidth:'25px'}}  className={lastClicked==2?classes.tabsIconSelected:classes.tabsIcon} ><ContactIcon/></ListItemIcon>         
            { open && <ListItemText style={{paddingTop:3, paddingLeft:"10px"}} disableTypography = {true } className={lastClicked==2?classes.tabsTextSelected:classes.tabsText} primary={'Contact List'}/> }                                     
          </ListItem> */}
          {/* <ListItem  id={"upload"} alignItems="center" button key={'Upload File'} onClick= {e=>onClickHandler(e,3)}>
            <ListItemIcon style={{minWidth:'25.2px'}}  className={lastClicked==3?classes.tabsIconSelected:classes.tabsIcon} ><UploadIcon /></ListItemIcon>         
            { open &&<ListItemText style={{paddingTop:3, paddingLeft:"14px"}}disableTypography = {true } className={lastClicked==3?classes.tabsTextSelected:classes.tabsText} primary={'Upload Data'}/>}                                      
          </ListItem> */}
          </List>
        </BrowserRouter>
            {/* <Typography  variant={"caption"} align={"center"} className={classes.space }> {props.time} </Typography >  */}
        <Clockv1/>
  
        <Typography variant={"caption"} align={"center"}> <Link style={{color:'white'}} role ={props.role} component={LinkBehavior}>{process.env.REACT_APP_VERSION}</Link></Typography> {/*so we know what version we are on */}
      
      </Drawer>

    </div>
  );
}

export default withRouter(Sidebar);
import React from 'react';
import {Button} from 'horizon-components-react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './style.css';

class ScrollDialog extends React.Component {
  state = {
    open: true,
    scroll: 'paper',
    userapplicationroleackexportcontrol:0, // need to change to getting from server value 
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleAgree = () => {  //for agree button
    let isAgreed= 1
    this.setState({ open: false, userapplicationroleackexportcontrol:isAgreed })
    this.props.history.push({pathname:'/personaldata', state:{userapplicationroleackexportcontrol:isAgreed},logintoken:this.props.location.state.logintoken,roletoken:this.props.location.state.roletoken,userapplicationroleid:this.props.location.state.userapplicationroleid})
  };

  handleDisagree = () => {  //for disagree button
    this.setState({ open: false });
    this.props.history.push('/login');
  };
  componentDidMount()  //check if gdpr agreed before
  {   
    if (this.state.userapplicationroleackexportcontrol===1) { this.props.history.push({pathname:'/auth',state:{userapplicationroleackexportcontrol:this.state.exportControlAck}})};

  }
  

  render() {
    return (
      <div>
        <div class = "overlay" >
        <Dialog
          maxWidth={'xl'}
          fullWidth={'true'}
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Export Control Data Disclaimer</DialogTitle>
          <DialogContent>
            <DialogContentText>
          

<p></p><b>Welcome to the AIRBUS/Hangar of the Future Data Exchange application.</b>
<p></p>
<p>If you continue to browse and use this website, you are agreeing to comply with the following statements. If you disagree with any part of them, please do not use the HOF Data Exchange application.</p>
<p>The application allows exchange of files. At the end of your action out of the platform, exported files may be stored. All exports are monitored.</p>
<p>It is your responsibility to use this application accordingly in the frame of your professional activity. Access from these countries is restricted regardless of network connection: Crimea Region of the Ukraine, Cuba, Iran, North Korea, Russia, Syria, Sudan, People's Republic of China.</p>
<p><b>	It is forbidden to upload, or to download already existing:</b></p>
<p>•	Personal data</p>
<p>•	Export control data</p>
<p>•	Secret data</p>
<p>	<b>You are expressly prohibited from attempting to:</b></p>
<p>•	Circumvent or interfere with any of the platform's authentication or security measures (including via any attempted intrusion or penetration testing)</p>
<p>•	Decompile, disassemble, scan, reverse engineer or discover any underlying ideas or algorithms of any software contained in the platform</p>
<p>•	Discover any source code of any component of the platform.</p>
<p><i>In case of doubt on risk associated with the data, please submit a support ticket or reach out to servicedesk@airbus.com</i></p>
<p style={{marginLeft:"40rem",marginTop:"3rem"}}><b>I agree with “Export Control and General usage policy Disclaimer” </b></p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div>
            <Button contained color="error" onClick={this.handleDisagree} style={{marginRight:"0.5rem"}}>
             NO
            </Button>
            <Button  contained color="success" onClick={this.handleAgree}>
              YES
            </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      </div>
    );
  }
}

export default ScrollDialog;

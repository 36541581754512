import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Tooltip from '@material-ui/core/Tooltip';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const styles = {
//   root: {
//     minWidth: '10rem',
//     maxWidth: '30rem',
//     overflow: 'auto',
//   },
// };

//MATERIAL UI HAS ERRORS IN SOME EXAMPLES WITH HOOKS . THIS CLASS IS A CONVERSION OF THOSE FUNCTIONS
class FriendList extends React.Component // used by "UploadFiles" and "MyUploads"
{

  state=
  {
    //contactList:[], // Initial list of users tagged with file in "MyUploads"
    userList:[],   // Overall list of users avaliable in the application
    selectedTags: []  // List to be updated to setRecipients for 'recipients' transactions
  }

  componentDidMount()
  {

    //this.populateUserList();
    setTimeout(()=>this.populateUserList(),1000); 
    if(this.props.originalRecipients.length>0) 
    {
      var users=this.props.originalRecipients;
      this.setState({contactList:users})
    }
  }
  populateUserList() // populate list of users avaliable for this user and application
  {
    var tempArr=[]
    this.props.contactList.map(value=>
      {
        tempArr.push(value.username);
      })
      this.setState({userList:tempArr})
      this.populateGroupList()
  }
  
    
    populateGroupList()  // populate list of groups avaliable for this user and application
    {
      var tempArr=this.state.userList.slice()
      this.props.groupList.map(value=>
        {
          tempArr.push(value.groupname);
        })
        this.setState({userList:tempArr})
        this.props.setFullRecipientList(tempArr)
      }
     
  
  setContactList=(name)=>  // for reassignment of new users to selected file
  {
    this.setState({contactList:name})
  }

  handleChange = (event,value) => {  // map selected value to array 
    this.setState({
      selectedTags: value
    }, () => {
      // This will output an array of objects
      // given by Autocomplete options property.
      // console.log(this.state.userList.indexOf(value[value.length-1]));
      this.props.setRecipients(this.state.selectedTags)
    }
    );

  }

  componentDidUpdate(prevProps)
  {
    if(this.props.recipients!==prevProps.recipients) this.setContactList(this.props.recipients)
  }

  render()
  {
    const { classes } = this.props;
    return (
      <div>
        <FormControl >
          {/* <InputLabel id="demo-mutiple-checkbox-label" >Recipients</InputLabel> */}
          <Autocomplete
              id="checkboxes-tags-demo"
            multiple
            disableCloseOnSelect
            options={this.state.userList }
            defaultValue={this.props.originalRecipients.sort()}   // can be replaced by this component state instead? --- for future review
            getOptionLabel={(option) => option }
            onChange={this.handleChange}
            input={<Input />}
            disabled={this.props.disabled}
            // renderOption={selected => selected.join(', ')}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox checked={selected} />
                <ListItemText primary={option}>   </ListItemText> 
                </React.Fragment>
      
            )}
            renderInput={(params) => (
              <TextField {...params}  style = {{width: 300}} variant="outlined" label="Checkboxes" />
              )}


            //MenuProps={MenuProps}
          >
            {/* {this.state.userList.map(name => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={this.state.personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem> */}
            {/* ))} */}
          </Autocomplete>
        </FormControl>
      </div>
    );
  }

}
export default FriendList;
import React from 'react';
import {Card, CardActions, CardActionArea, CardContent, Grid, makeStyles} from '@material-ui/core/';
import {Container} from 'horizon-components-react';
import { BrowserRouter, withRouter } from 'react-router-dom';




const useStyles = makeStyles({    // card is for airbus logo beside header
  card: {
    minWidth: "9rem",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});



function SimpleCard(props) {
  const classes = useStyles();

  const onClickHandler=(e,index)=>{ //for airbus logo routing

 
        if(index==0)    props.history.push( "/"+props.role+"/landing");   // code change: pass role dynamically now
        props.setTab(-1);
     
  }

  return (
  
    <Grid align-items-xs-center justify-xs-flex-end 
    >
        <BrowserRouter> 

        <Card>
        <CardActionArea>
    <Container  style ={{backgroundColor: '#00205b'}} className={classes.card}>   
  
      <CardContent onClick= {e=>onClickHandler(e,0)}>
     
      <img style ={{marginTop:'0.8rem'}}   //centers airbus logo
                       src="/images/airbus_logo_white_svg.svg"  />
               <CardActions/>        
      </CardContent>
  
      
    
      
    </Container>
    </CardActionArea>
    </Card>
    </BrowserRouter> 
    
    </Grid>

  );
}

export default withRouter(SimpleCard);
import React from "react";
import {Route, Switch} from "react-router-dom";
import DownloadFiles from "./DownloadFiles/DownloadFiles.js"
import UploadFiles from "./UploadFiles/UploadFiles.js"
import LandingPage from"./Landing/LandingPage.js"
import UploadData from "./UploadFiles/UploadData.js"
import {FileList,fetchUserContacts,fetchUserGroups,MyUploadedFileList} from "../components/FileList.js";
import Header from '../components/header/Header.js'
import Sidebar from "../components/sidebar/sidebar.js";
import { withStyles } from '@material-ui/core/styles';
import SimpleAlertMessage from '../components/alertMessage/SimpleAlertMessage';
import {Backdrop,CircularProgress} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Contacts from "./Contacts.js"
import moment from 'moment';
import Help from "./Help/Help.js";
import Changelog from "./Changelog/Changelog.js";

//WE SHOULD IMPLEMENT SIDEBAR AND HEADER HERE
const drawerWidth = "9rem";
const styles = theme => ({
    root: {
      display: 'static',
    },
    content: {
      flexGrow: 1,
     padding: "0.5rem",
    },

    shiftTextLeft: {
      marginLeft: "4.5rem"
    },
    shiftTextRight: {
      marginLeft: drawerWidth,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  });
class User extends React.Component
{
    state={
        isAuthenticated:null,
        role:null,
        user: //userdata from log in/jwt
        {
            username:null,//"adminA@hotmail.com",//null,
            userid:null,//22,
            company:null,//"Company A",//null
            companyid:null,//"3"
            logintokenexpirydatetime:null,
            authenticateddatetime:null
        },
        fileList:[], //list of  files available for download.WE KEEP THIS AT A VIEW LEVEL SO ITS ACCESSIBLE FROM CHILD COMPONENTS  //CHANGE TO CONTEXT
        //myUploadedFileList:[], //list of  files available for download.WE KEEP THIS AT A VIEW LEVEL SO ITS ACCESSIBLE FROM CHILD COMPONENTS  //CHANGE TO CONTEXT
        contactList:[], //list of user contacts.WE KEEP THIS AT A VIEW LEVEL SO ITS ACCESSIBLE FROM CHILD COMPONENTS //CHANGE TO CONTEXT
        groupList:[], //list of user groups
        drawerIsOpen:true,//temp for conditional render testing
        updateFlag:true,
        alert:null,
        isAlert:false,
        isBackdrop:false,
        selectedTab:-1,
        newFiles:0,
        fileListLoaded:false
    }

    componentDidMount()
    {       
        if((this.props==null)||(this.props.location.state==null)||(this.props.location.state.isAuthenticated==null)) {
            this.props.history.push('/auth',{redirectLocation: {...this.props.location}})
        }  //auth checks, else reroute to auth
  
        else {
            this.setState({isAuthenticated:this.props.location.state.isAuthenticated,role:this.props.location.state.role[0].applicationrolename.toLowerCase()})
            var user =
            {
                username:this.props.location.state.userData.username,//"adminA@hotmail.com",//null,
                userid:this.props.location.state.userData.userid,//22,
                company:this.props.location.state.userData.organizationname,//"Company A",//null
                companyid:this.props.location.state.userData.organizationid,//"3"
                logintokenexpirydatetime:this.props.location.state.userData.logintokenexpirydatetime,
                authenticateddatetime:this.props.location.state.userData.authenticateddatetime,
                role:this.props.location.state.userData.userapplicationrole[0].applicationrolename,
                loginToken:this.props.location.state.logintoken
            }
            //console.log(this.props.location.state.userData)
            this.setState({user:user})
        }

        this.setState({isBackdrop:true})
        setTimeout(()=>{this.setState({isBackdrop:false})},500) //we show loading for 0.5 seconds to allow content to pull

        setTimeout(()=>{
            this.updateFileList();
            //this.checkNewFiles()
            this.getUserContacts();
            this.getUserGroups();
        },200) // previously 10
    }
    componentWillUnmount()
    {
        this.setState({
            isAuthenticated:null,
            role:null,
            user: //userdata from log in/jwt
            {
                username:this.null,//"adminA@hotmail.com",//null,
                userid:this.null,//22,
                company:null,//"Company A",//null
                companyid:null//"3"
            },
            fileList:[], //list of  files available for download.WE KEEP THIS AT A VIEW LEVEL SO ITS ACCESSIBLE FROM CHILD COMPONENTS
            //myUploadedFileList:[],
            contactList:[],
        })
    }
    drawerState=(isOpen)=>{this.setState({drawerIsOpen:isOpen})}
    setTab=(tab)=>{
        this.setState({selectedTab:tab})
        this.setRoute(tab)
    }

    componentDidUpdate(prevProps)
    {
        if(this.state.updateFlag) {
            this.updateFileList();
        }
        //if (this.props.location !== prevProps.location)this.onRouteChanged(this.props.location);
    }

    triggerUpdate() 
    {
        this.setState({updateFlag:true})
    }

  
    //calls FileList api to retrieve list of available files for download. Takes in usernames as search query  //MAYBE MAKE THIS A PROMISE
    updateFileList=()=>
    {
        //this.showLoading(true);
        FileList(this.state.user, (res) => {
            //success
            var temp=res.reverse();
            this.setState({fileListLoaded:true})
            this.setState({fileList:temp});
            this.checkNewFiles();
            //this.showLoading(false);
        },(err) => {
            //error
            alert(err);
            this.setState({fileListLoaded:true})
            //this.showLoading(false);
        })

        this.setState({updateFlag:false})
    } 

    checkNewFiles=()=>{     //check if new files for download is available
        var numFiles=0;
             
        for(var i=0;i<this.state.fileList.length;i++)
        {
            var temp =this.state.fileList[i]
            if(temp.isnew && temp.senderusername!==this.state.user.username)
            {          
                numFiles++;
            }
        }
        this.setState({newFiles:numFiles})
    }

    //calls FileList api to retrieve list of "friends". Takes in company id as search query
    getUserContacts=()=>
    {
        fetchUserContacts(this.state.user.userid, (res) => {
            //success
            this.setState({contactList:res});
        },(err) => {
            //error
            alert(err);
        })
    } 

    //get list of groups user belongs to
    getUserGroups=()=>
    {
        fetchUserGroups(this.state.user.userid, (res) => {
            //success
            this.setState({groupList:res})
        },(err) => {
            //error
            alert(err);
        }) 
    }


    
    //handles setting of new page (pagination)
    handleChangePage = (e,newPage) =>this.setState({page:newPage});

    //handles setting of new numver of rows per page (pagination)
    handleChangeRowsPerPage = event => this.setState({rowsPerPage:+event.target.value ,page:0});

    // //handles alert messages to display according to various situations , i.e. error, success, information, warning
    sendAlert=(title,classType,timeout)=>
    {
        var temp={title:title,classType:classType}
        this.setState({alert:temp},()=>{this.setState({isAlert:true})}) //sets alert values, then shows alert
        setTimeout(()=>this.setState(this.setState({isAlert:false})),timeout) //waits "timeout" seconds. then hides alert
    }

    //Enable/Disable the loading spinner
    showLoading=(value)=>
    {
        //this.setState({isBackdrop:value})  //DISABLED UNTIL GET REQUESTS ARE MORE STABLE
    }

    //called whenever we reroute
    onRouteChanged(loc) {
        //console.log(loc.pathname);
        //console.log(this.props.match.path)
      }

      //we standardize route setting to this function to prevent overlapping calls
    setRoute(index)
    {
        if(index==0) {this.props.history.push( "/"+this.state.role+"/uploadFile");}
        if(index==1) {this.props.history.push(  "/"+this.state.role+"/downloadFile");}
        if(index==2) {this.props.history.push(  "/"+this.state.role+"/contacts");}
        if(index==3) {this.props.history.push(  "/"+this.state.role+"/uploadData");}
        if(index==4) {this.props.history.push(  "/"+this.state.role+"/help");}
        if(index==5) {this.props.history.push(  "/"+this.state.role+"/changelog");}
        if(index==-1) {this.props.history.push('/auth');}
    }

    //to decrement new file counter locally when file is downloaded
    fileHasBeenDownloaded=(UUID)=>
    {
        var index=this.state.fileList.findIndex(x=> x.UUID===UUID) //if UUID exists,store index
        if(index>-1) //if exists , this value will be 0 or greater
        {
            
            if(this.state.fileList[index].isnew)//check if the file is new 
            {
                var fl=this.state.fileList.slice(); //slice the state array, as we should never mutate directly
                fl[index].isnew=0; //set the isnew value at the index to 0
                var numNewFiles=this.state.newFiles;   //get the newfiles counter
                if(this.state.newFiles>0) numNewFiles--; //decrement the counter , we check for zero just in case
                this.setState({newFiles:numNewFiles,fileList:fl}) //write back to state
            }
        }
    }

    render()
    {
        const { classes } = this.props;
        const { path, url } = this.props.match;
        return(
            
            <div id="parent" >
                <div id="fixedUI" >
                {/* <Button variant="primary" type="submit" onClick={()=>this.toggleMode()} style={{color: 'white', fontWeight: 'bold'}} >
                    Toggle
                </Button> */}
                <Box pb={15}> </Box>
                <Header newFiles={this.state.newFiles} setTab={this.setTab.bind(this)} selectedTab={this.state.selectedTab} username={this.state.user.username} role={this.state.role}/>
                <Sidebar role={this.state.role} drawerState={this.drawerState.bind(this)} setTab={this.setTab.bind(this)} selectedTab={this.state.selectedTab} />

                
                {/* {this.state.tempBool &&<DownloadFiles role={this.state.role} user={this.state.user} fileList={this.state.fileList} />}
                {!this.state.tempBool&& <UploadFiles role={this.state.role} user={this.state.user}   contactList={this.state.contactList} />} */}
            </div>

            <main className={classes.content}>
                <div id="dynamicUI" className={this.state.drawerIsOpen ? classes.shiftTextRight : classes.shiftTextLeft}>
                    {this.state.isAlert && <SimpleAlertMessage title={this.state.alert.title} classType ={this.state.alert.classType}/> }  
                        <Backdrop className={classes.backdrop} open={this.state.isBackdrop} >
                            <CircularProgress color="inherit" />
                        </Backdrop>
  
                        <Switch>
                        <Route exact path={path}>
                        </Route>
                        <Route path={`${path}/landing`}>
                        <LandingPage newFiles={this.state.newFiles} role={this.state.role} user={this.state.user} fileList={this.state.fileList} setTab={this.setTab.bind(this)}/>
                        </Route>
                        <Route path={`${path}/downloadFile`}>
                            <DownloadFiles role={this.state.role} user={this.state.user} fileList={this.state.fileList} 
                            triggerUpdate={this.triggerUpdate.bind(this)} sendAlert={this.sendAlert.bind(this)} showLoading={this.showLoading.bind(this)}  fileHasBeenDownloaded={this.fileHasBeenDownloaded.bind(this)} />
                        </Route>
                        <Route path={`${path}/uploadFile`}>
                            <UploadFiles groupList={this.state.groupList} role={this.state.role} user={this.state.user}   contactList={this.state.contactList} triggerUpdate={this.triggerUpdate.bind(this)} 
                            showLoading={this.showLoading.bind(this)} sendAlert={this.sendAlert.bind(this)} fileList={this.state.fileList} setTab={this.setTab.bind(this)} fileListLoaded={this.state.fileListLoaded} />
                        </Route>
                        <Route path={`${path}/contacts`}>      
                        <Contacts groupList={this.state.groupList} contactList={this.state.contactList} user={this.state.user}/>                         
                        </Route>
                        <Route path={`${path}/uploadData`}>
                            <UploadData role={this.state.role} user={this.state.user}   contactList={this.state.contactList} triggerUpdate={this.triggerUpdate.bind(this)} 
                            showLoading={this.showLoading.bind(this)} sendAlert={this.sendAlert.bind(this)}/>
                        </Route>
                        <Route path={`${path}/help`}>
                        <Help />
                        </Route>
                        <Route path={`${path}/changelog`}>
                        <Changelog />
                        </Route>
                    </Switch>
                </div>
            </main>
        </div>
            
        )
    }

}

export default withStyles(styles)(User);
import React, { Component } from 'react'
import {NavLink, Redirect} from 'react-router-dom'
// import {Button} from 'horizon-components-react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import {Chip} from 'horizon-components-react';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import {
  Button,
  Grid,
  TextField,
  Card,
  Typography,
  Box,
  Collapse,
  IconButton,
  Backdrop, CircularProgress
} from "@material-ui/core";
import { CssBaseline, ThemeProvider } from "horizon-components-react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Infoicon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  root: {
    // height: "100vh",
    backgroundColor: "#fff",
  },
  cardContent: {
    justifyContent: "space-between",
  },
  cardTitleContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#BDBDBD",
    // backgroundColor: "#00205b",
  },
  username: {
    padding: "16px 10%",
  },
  password: {
    padding: "16px 10%",
  },
  card: {
    height: "fit-content",
  },
  loginBtnContainer: {
    padding: "16px 10% 2%",
  },

  Link: {
    padding: "0.25px 10%",

  },

  inputx: {
    "& .MuiOutlinedInput-input": {
      padding: 12,
    },
  },
  alertOpen: {
    "& .MuiCollapse-wrapper": {
      height: 48,
      width: "100%",
      "& > div": {
        color: "#FFF",
        backgroundColor: "#D32F2F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  alertClosed: {
    paddingBottom: 48,
  },

  successalertOpen: {
    "& .MuiCollapse-wrapper": {
      height: 48,
      width: "100%",
      "& > div": {
        color: "#FFF",
        backgroundColor: "#D32F2F",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  successalertClosed: {
    paddingBottom: 48,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    transition: "ease-in 200ms",
  },
  loginBtn: {
    color: '#FFF',
    backgroundColor: "#00205b",
    '&:hover': {
      backgroundColor: "#283593",
      "&:disabled": {
        backgroundColor: "#00205b"
      }
    }

  },
  CancelBtn: {
    color: '#FFF',
    backgroundColor: "#fbc02d",
    '&:hover': {
      backgroundColor: "#fdd835",
      "&:disabled": {
        backgroundColor: "#fdd835"
      }
    }
    

  },

confirmCancelBtn: {
  color: '#FFF',
  backgroundColor: "#283593",
  '&:hover': {
    backgroundColor: "#00205b",
    "&:disabled": {
      backgroundColor: "#00205b"
    }
  }
  

},

  
});


// const styles = theme => ({
//   root: {
//     width: "80%",
//     backgroundColor: "#bdbdbd",
//     color: "#fafafa",
//     dragable: false,
//     borderRadius: "4px",
//     border: "1.5px solid #304ffe",
//     textAlign: "center",
//     textAlignLast: "center"
//   },
//   icon: {
//     width: 18,
//     height: 18,
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   chip: {
//     margin: 2,
//   }
//   });


class AddUser extends Component {

state = {
    open: false,
    input: "",
    organizationid: 1,
    organizationData: [],
    applicationrolename: [],
    applicationRoles: [],
    openErrorMessage: false,
    username: "",
    usernameValid: true,
    cancelOpen:false,
    userRole:'User',
    validationError:'',
    successAlert:'',
    addedUserID:null,
    usersToAdd:[],
    groupNameToBeUsed:"",
    groupIDToBeUsed:null
  }


handleErrorClose = () => {
  this.setState({ openErrorMessage: false });
};

handlePageChange(event) {
  event.preventDefault();
  this.props.history.push('/admin/accountManagement')
}

componentDidMount() {
  this.handleGetGroups()
// this.handleGetUsers()

  axios.get(process.env.REACT_APP_SSO_API + `/organization`, {
    headers: {
      "x-api-key": process.env.REACT_APP_API_KEY
    }
  })
    .then(res => {
      console.log(res.data);
      this.setState({ organizationData: res.data })
    })
    .catch(err =>{
      console.log(err);
    })//get and set data of organizations
    
    axios.get(process.env.REACT_APP_SSO_API + `/applicationrole`, {
            headers: {
              "x-api-key": process.env.REACT_APP_API_KEY
            }
          })
            .then(res => {
              console.log(res.data);
              var newData = [];
              var applicationRoleName = "";
              res.data.map((data, index) => {
                data.applicationrole.map((applicationRole, roleIndex) => {
                  newData.push({ name: data.applicationname + " (" + applicationRole.applicationrolename + ")", id: applicationRole.applicationroleid ,role:applicationRole.applicationrolename})
                })
              })
              this.setState({applicationRoles: newData})
              console.log(this.state.applicationRoles)
            })
            .catch(err =>{
              console.log(err);
            })//get and set data of applicationroles
}

 handleOpenCancelDialog = () => {
  this.setState({ cancelOpen: true })
}; //handle close of dialog for cancelling

handleCloseCancelDialog = () => {
  this.setState({ cancelOpen: false })
}; //handle close of dialog for cancelling

// handleChange = (event) => {
//   this.setState({organizationid: event.target.value});
//   console.log(event.target.value);
// }; //handle change of select component
  
handleRoleChange = (event) => {
  this.setState({userRole:event.target.value})
};

validateUsername =(username) =>{ // validate username syntax
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(username)
}

handleUsernameChange=(e) =>{ //handle field input
    const username = e.target.value
    const usernameValid = this.validateUsername(username) 

    this.setState({
      username: e.target.value,
      usernameValid: usernameValid
    })

    if (usernameValid || username.length == 0) { this.setState({ usernameErrorMessage: "" }) }
    else { this.setState({ usernameErrorMessage: "Invalid Username" }) }
}

handleCloseAllDialog = () => {
  this.props.handleCloseAddUserDialog();
  this.props.getData();
  this.setState({isBackdrop:false})
  this.props.sendAlert("User Added Successfully!","SuccessAlert",2000);

}
 handleAdd = () => {
  // if(this.state.input == "" || this.state.organizationid == "" || this.state.applicationrolename.length == 0){
  //   this.setState({ openErrorMessage: true});
  // }//if input or organization or applicationrole is empty, display error message
  // else{

  var applicationRolesArr = [];
  this.state.applicationRoles.map((applicationRole, id) => {
if(this.state.userRole== applicationRole.role)
    applicationRolesArr.push({ applicationroleid: applicationRole.id })
this.setState({isBackdrop:true})
  })//map and push applicationroles into array for POST
  console.log(applicationRolesArr)
  axios.post(process.env.REACT_APP_SSO_API + '/user', [{
      username: this.state.username,
      organizationid: this.state.organizationid,
      userapplicationrole: applicationRolesArr
    }], {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
    })
    .then(response => { 
      console.log(response)
      const addedUserID =response.data[0].userid

      var usersToAdd = [];
      usersToAdd.push({ userid: addedUserID })
      console.log(usersToAdd)
      this.setState({usersToAdd:usersToAdd})
      this.setState({addedUserID},()=>{this.handleAddUserToGroup()})
    

      //map and push users into array for POST
    }
 
      // this.props.history.push('/admin/accountManagement')
    )
    .catch(error => {
      console.log(error.response)
      this.setState({validationError:'Error Adding User, Check user email again',isBackdrop:false})
      setTimeout(() => {this.setState({validationError:''})}, 4000);

    });
  
} 

handleAddUserToGroup = () => {

  //if any inputs are blank, display error message



  axios.patch(process.env.REACT_APP_SSO_API + '/group', [{
    groupname: this.state.groupNameToBeUsed,
    groupid:this.state.groupIDToBeUsed,
    organizationid: 1,
    applicationid: 1,
    userid: 1,
    groupisprivate: 1,
    usergroup: this.state.usersToAdd
  }], {
          headers: {
              'x-api-key': process.env.REACT_APP_API_KEY,
              'Content-Type': 'application/json'
          }
  })
  .then(response => { 
    console.log(response)

    setTimeout(() => {this.handleCloseAllDialog()}, 4000);

  })
  .catch(error => {
    console.log(error.response)
    this.setState({isBackdrop:false})

  });
  
} //handles post


handleGetGroups=()=>{
  axios.get(process.env.REACT_APP_SSO_API + `/group`, {
    params : {
      username:this.props.myUsername
  }

  
  })
    .then(res => {
      console.log(res.data);
      this.setState({groupNameToBeUsed:res.data[0].groupname,groupIDToBeUsed:res.data[0].groupid})
     
    })
    .catch(err =>{
      console.log(err);
    })
  //handles post
  }

  render(){
    const {classes} = this.props;
    // const handleClickOpen = () => {
    //   this.setState({ open: true })
    // }; //handle open of dialog for cancel button
  

  



    // const ITEM_HEIGHT = 48;
    // const ITEM_PADDING_TOP = 8;
    // const MenuProps = {
    //   PaperProps: {
    //     style: {
    //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //       width: 250,
    //     },
    //   },
    // }; //multiple select styling


    return(  <ThemeProvider theme="airbus">
    <Backdrop className={classes.backdrop} open={this.state.isBackdrop}>
      <CircularProgress style={{ color: "#FFF" }} />

      <CssBaseline />
      <Dialog
      open={this.state.cancelOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Cancel Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By cancelling, you will lose all the changes made on the form.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleCloseCancelDialog } className={classes.CancelBtn}>
          Cancel
        </Button>
        <Button onClick={this.props.handleCloseAddUserDialog} className={classes.confirmCancelBtn} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
      
    </Backdrop>
    <CssBaseline />
    
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid >
        <Card className={classes.card}>
          <Grid
            container
            className={classes.cardContent}
            direction="column"
          >
            <Grid item className={classes.cardTitleContainer}>
              <Typography
                align="center"
                variant="h4"
                style={{
                  fontWeight: "bold",
                  color: "#00205b",
                }}
              >
                     User Management
              </Typography>
              {/* <Typography variant="h4" align="center" style={{ color: '#FFEB3B', fontWeight: 'bold' }}>
                User Management
              </Typography> */}

            </Grid>
            <Grid
              item
              xs={12}
              className={
               ( !!this.state.validationError ? classes.alertOpen: classes.alertClosed) ||(( !!this.state.successAlert
                    ? classes.successalertOpen
                    : classes.successalertClosed))
              }
            >
              <Collapse in={!!this.state.validationError || !!this.state.successAlert} timeout="auto">
               <div style={{textAlign:"center"}}> {this.state.validationError || this.state.successAlert}</div>
     
              </Collapse>
     
            </Grid>
            <Grid container>
   
              <Grid item xs={12} className={classes.username}>
           Username
                <TextField
                  className={classes.inputx}
                  // defaultValue={this.props.location.state.username}
                  value={this.state.username}
                  fullWidth
                  name="username"
                  onChange={this.handleUsernameChange}
                  placeholder="Enter User's Email"
                  helperText={this.state.userNameErrorMessage}
                  error={!this.state.usernameValid && this.state.username.length > 0}
                  variant="outlined"
                  style={({paddingTop:".5rem"})}
                />
              </Grid>
              <Grid item xs={12} className={classes.password}>
              <FormControl component="fieldset">
  <FormLabel>User roles</FormLabel>
  <RadioGroup row name="userroles" value={this.state.userRole} onChange={this.handleRoleChange}>
    <FormControlLabel value="User" control={<Radio />}  label={ <div>User <Tooltip title={<div style={{fontSize:"1rem"}}>View & Upload files </div>}>
   <IconButton style={{ padding: 0 }}>
     <Infoicon />
   </IconButton>
 </Tooltip>
 </div>} />
    <FormControlLabel value="Admin" control={<Radio />} label={ <div>Admin <Tooltip fontSize={200} title={<div style={{fontSize:"1rem"}}>Manage users & View user uploaded files</div>}>
   <IconButton style={{ padding: 0 }}>
     <Infoicon />
   </IconButton>
 </Tooltip>
 </div>}>  <div></div>  </FormControlLabel>




  </RadioGroup>
</FormControl>
              </Grid>
            </Grid>
           
             
            </Grid>

            <Grid
              container
              className={classes.loginBtnContainer}
              justify="center"
            >

              <Grid item xs={12}>
                <Button
                  disabled={!(this.state.username.length>0 && this.state.userRole && this.state.usernameValid )}
                  variant="contained"
                  className={classes.loginBtn}
                  type="submit"
                  onClick={this.handleAdd}
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

         
       
            <Grid
              container
              className={classes.loginBtnContainer}
              justify="center"
            >

              <Grid item xs={12}>
                <Button
                  // disabled={this.state.usernameValid}
                  variant="contained"
                  className={classes.CancelBtn}
                  type="submit"
                  onClick={this.handleOpenCancelDialog}
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              
            </Grid>
        </Card>
      </Grid>

    </Grid >

  </ThemeProvider >)

//       return(
//           <div>
//               <h3>Add User</h3>
//               {/* <Box display="flex"
//               p={10}
//               m={5}
//               flexDirection="column"
//               borderRadius="50px"
//               border="2px solid #304ffe"
//               justifyContent="center"
//               alignItems="center"
//               bgcolor="#eeeeee"
//               > */}

// <Grid container>
//                 <Grid item xs={12} className={classes.username}>
//                   <TextField
//                     className={classes.inputx}
//                     // defaultValue={this.props.location.state.username}
//                     value={this.state.username}
//                     fullWidth
//                     name="username"
//                     onChange={this.handleChange}
//                     placeholder="Username"
//                     helperText={this.state.userNameErrorMessage}
//                     error={!this.state.usernamevalid && this.state.username.length > 0}
//                     variant="outlined"
//                   />
//                 </Grid>
//                 </Grid>
//                   <TextInput
//                   className={classes.root}
//                   dark="true"
//                   placeholder="User Name"
//                   id="username"
//                   value={this.state.input}
//                   onInput={event => this.setState({input: event.target.value})}
//                   /><br/>
//                         <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={this.state.organizationid}
//                         onChange={this.handleChange}
//                         displayEmpty
//                         className={classes.root}
//                          >
//                           <MenuItem disabled value="">
//                             Organization Name
//                           </MenuItem>
//         {this.state.organizationData.map((data, id) => (
//           <MenuItem value={data.organizationid}>
//             {data.organizationname}
//           </MenuItem>
//         ))}
//           </Select><br/> <br/>
//           <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={this.state.organizationid}
//                         onChange={this.handleChange}
//                         displayEmpty
//                         className={classes.root}
//                          >
//                           <MenuItem disabled value="">
//                            Group Name
//                           </MenuItem>
//         {this.state.organizationData.map((data, id) => (
//           <MenuItem value={data.organizationid}>
//             {data.organizationname}
//           </MenuItem>
//         ))}
//           </Select>
//           <Select
//           className={classes.root}
//           labelId="demo-mutiple-chip-label"
//           id="demo-mutiple-chip"
//           displayEmpty
//           multiple
//           value={this.state.applicationrolename}
//           onChange={handleChangeMultiple}
//           input={<Input id="select-multiple-chip" />}
//           renderValue={(selected) => {
//             if (selected.length === 0) {
//               return "Application Role(s)";
//             }
//             return(<div className={classes.chips}>
//               {selected.map((value) => (
//                 <Chip key={value.name} label={value.name} className={classes.chip} />
//               ))}
//             </div>)
//           }}
//           MenuProps={MenuProps}
//         >
//           <MenuItem disabled value="">
//             Application Role
//           </MenuItem>
//           {this.state.applicationRoles.map((name, idx) => (
//             <MenuItem key={name.id} value={name}>
//               <Checkbox checked={this.state.applicationrolename.indexOf(name) > -1} />
//               {name.name}
//             </MenuItem>
//           ))}
//         </Select>
//               <br/><br/><br/>
//               <Box
//               display="flex"
//               width="80%"
//               justifyContent="flex-end">
//               <Button
//               color="default"
//               onClick={handleClickOpen}
//               >
//                       Cancel
//               </Button>
//     <Dialog
//       open={this.state.open}
//       onClose={handleClose}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//     >
//       <DialogTitle id="alert-dialog-title">{"Cancel Confirmation"}</DialogTitle>
//       <DialogContent>
//         <DialogContentText id="alert-dialog-description">
//           By cancelling, you will lose all the changes made on the form.
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose} color="default">
//           Cancel
//         </Button>
//         <Button onClick={this.handlePageChange.bind(this)} primary="true" autoFocus>
//           Confirm
//         </Button>
//       </DialogActions>
//     </Dialog>
//               &nbsp;
//               <Button
//               primary="true"
//               onClick={handleAdd}>
//                       Create User
//               </Button>
//               </Box>
//               {/* </Box> */}
//             <Snackbar open={this.state.openErrorMessage} autoHideDuration={6000} onClose={this.handleErrorClose}>
//            <Alert onClose={this.handleErrorClose} severity="error">
//               Invalid input. Input(s) cannot be blank.
//            </Alert>
//            </Snackbar>
//       </div>
//       )
  }
}

export default withRouter(withStyles(styles)(AddUser));
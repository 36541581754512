import React, { Component } from 'react';
import axios from "axios";
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination,Paper,Typography,Tabs,Tab} from '@material-ui/core'
import{Stepper,Step,StepLabel,StepContent,Grid} from '@material-ui/core'
import {Button,IconButton} from 'horizon-components-react'
import {CssBaseline,ThemeProvider} from 'horizon-components-react';
import{ContactSupportOutlined, Delete,Refresh}  from '@material-ui/icons/';
import{determineFileExt,FileNameOnly} from '../../components/regex.js';
import {Progress} from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import'./MuiTableCell.css';
import { TabContainer } from 'react-bootstrap';
import MyUploads from './MyUploads.js'
import { MyUploadedFileList } from '../../components/FileList.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';

const styles = theme => ({
    root: {
        width: '100%',
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
      default_tabStyle:{
        fontSize:"0.80rem",
        color: 'white',
        backgroundColor: '#00205b',
        opacity: 1,
        textTransform:'capitalize'
       },
        active_tabStyle:{
        fontSize:"0.80rem",
        fontWeight:'bold',
        color: '#fbca33',
        backgroundColor: '#00205b',
        opacity: 1,
        textTransform:'capitalize',
       }
    });

axios.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY; 
axios.defaults.headers.common['Content-Type'] = 'application/json';

class UploadFiles extends Component
{
    state = {
        page:0, //current page for pagination
        rowsPerPage:10, //total rows to display per page
        rows:[], //content per row
        recipients:[], //
        fullRecipientList:[],
        finalRecipients:null,
        loaded: 0,
        selectedFiles:[],
        activeStep:0,
        activeTab:0,
        streaming:false,
        cycle:false, //keeps track of 1 complete upload cycle
        myUploadedFileList:[],
        transact:0,  
        openDialog:false,
        showNonPdfError:false,
    }

    refreshPage = () => {
        this.setState(
          {activeStep:0,finalRecipients:null,recipients:[],selectedFiles:[],rows:[],loaded:0,cycle:false,transact:0}
        )
      }

       handleClose = () => {
       this.setState({openDialog:false})
      }

    //handles submitting upload requestes to database. is an ASYNC function, so that multiple files can be placed in a queue
    handleSubmit= async (e)=>
    {
        this.setState({streaming:true,cycle:true})
        e.preventDefault();
        let promises=[];
        this.props.showLoading(true)
        this.state.selectedFiles.map((file,index)=>{
            promises.push(this.sendDataToServer(file,index))
        });
        Promise.all(promises)
            .then(()=>{
                this.setState({selectedFiles:[],loaded:0,recipients:[]})
                console.log("all files sent!")
                this.props.showLoading(false)
                this.setState({streaming:false})
            })
    }

    //#region DB   //MAYBE SHOULD EXTRACT THIS TO A FUCTIONAL COMPONENT
    sendDataToServer = (file,index)=>{
        
        return new Promise((resolve,reject)=>{
            var fileKey;
            let url;
            var self = this;
        
            //console.log(process.env.REACT_APP_BASE_URL+'/api/fileStatus/addFileKeySharing')    
            //insert into status database
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/fileStatus/addFileKeySharing`,{
                
                companyName : this.props.user.company, //users company
                fileCat : "ARC", //hardcode this for now
                fileExt : file.fileExt,
                fileName : file.fileName,
                USER_ID: this.props.user.userid,
                Company_ID : this.props.user.companyid,
                senderusername:this.props.user.username,
                recipientlist:this.state.finalRecipients

            })
            // get signed url
                .then(res=>{
                    console.log(res)
                    fileKey = res.data;
                    
                    
                    return axios.get(`${process.env.REACT_APP_PUT_SIGNED_URL}`,{
                        params:{
                            fileKey
                        }
                    })
                })
                // set file to s3
                .then(res => {
                    
                    let signedUrl = res.data;
                    //console.log(signedUrl);
                  
                    return axios({
                        method: 'PUT',
                        url : signedUrl,
                        data: file.file, //we access the full file read in
                        // withCredentials: true,
                        // crossdomain: true,
                        config: { headers: {
                            'Content-Type':  'multipart/form-data',
                            'Content-Encoding': 'base64'
                        }},
                        onUploadProgress: ProgressEvent => {
                            let arr = self.state.loaded;
                            arr = (ProgressEvent.loaded /(ProgressEvent.total)*100);
                            self.setState({
                                loaded : arr
                            }) }
                    })
                })
                // // change the acl of the file to be public
                .then(response => {
                    url = response.config.url;
                    url = url.substring(0,url.indexOf('?'));

                    return "done";
                })
                //insert fileKey and SheetName into data
                .then(res=>{ 
                    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/addSheetName`,{
                        fileKey,
                        sheetName: file.sheetName
                    })
                })
                // add the url and success upload not
                .then(res=>{
                    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/fileStatus/S3`,{
                        fileKey,
                        url
                    })
                })
                // redirect to index
                .then((res)=>{
                    // redirect to index page
                    this.props.sendAlert("Your files have been uploaded successfully!","SuccessAlert",2000);
                    this.props.triggerUpdate();
                    this.handleStep("handleNext");
                    document.getElementById("input").value=null;
                    resolve();
                })
                .catch(err=>reject(err.response));
        })
    }
    //#endregion

    componentDidUpdate(prevProps,prevState)
    {
        if(this.state.selectedFiles!==prevState.selectedFiles) { this.setState({myUploadedFileList:this.props.fileList});} //if selectedFileData has been updated, update "rows " value to update UI
        
         if(this.state.activeStep!==prevState.activeStep && this.state.activeStep===1) this.stripGroupUsers(); //if we are past selecting recipients, strip the group info for individual recipients

    }

    getPureFileName(fileName=""){
        return fileName.split('.pdf')[0];
    }

    checkFileNameMatch(existingFile='',newFile=''){
        const existingFileArray = existingFile.split('.pdf')[0].split('_');
        const newFileArray = newFile.split('.pdf')[0].split('_');
        console.log('existingFileArray',existingFileArray);
        console.log('newFileArray',newFileArray,'\n\n');
        return newFileArray.every((segment,idx) => segment===existingFileArray[idx]);

        /*
        ['abc','123','2']
        ['abc','123']

        ['noUnderscore','4','3']
        ['noUnderscore','4']
         */
    }

    //handles change in "fileinput " info
    onChangeHandler = (e) => {
        const existingFilenames =this.props.fileList.map(element=>this.getPureFileName(element.fileKey))
        // console.log('existingfile',existingFilenames)
        // console.log('selected files',this.state.selectedFiles)

        const filenamesFromSelectedFiles = this.state.selectedFiles.map(f=>this.getPureFileName(f.fileName));
        // console.log('filenaemsFrom',filenamesFromSelectedFiles)

        var filesArr=Array.from(e.target.files).map(file =>{
   

            const fixUpperPDF = file => file.name.replace('.PDF','.pdf');
            var fileBeingUploaded=fixUpperPDF(file)

            if(!fileBeingUploaded.includes('.pdf')){
                this.setState({showNonPdfError:true})
                return;
            }
        this.setState({showNonPdfError:false});

             console.log('fn',file.name)
    

    
            const appendValue = existingFilenames.filter(f =>this.checkFileNameMatch(f,fileBeingUploaded)).length
            + filenamesFromSelectedFiles.filter(f =>this.checkFileNameMatch(f,fileBeingUploaded)).length
    


            // console.log('append value',appendValue)

         if (appendValue>0){
                this.setState({openDialog:true})

           return new File([file],`${fixUpperPDF(file).split('.pdf')[0]}_${appendValue}.pdf`);
         }
         return new File([file],fixUpperPDF(file));
        }).filter(Boolean)

        // console.log('existingfileNamesArrayFinal',filesArr)
      

this.filestoBase64(filesArr).then((result)=>{
    var temp=this.state.selectedFiles.concat(result)
    this.setState({selectedFiles:temp})
}) ;

  //     if (newArray.includes(fileBeingUploaded)){console.log('true')
//    this.createData()


    
  //  }
}
        //var numOfTrue = (newArray.filter(x => x === file.fileName).length)+=1;
        // filesArr.find(v => v.filename === xx).name = "xx"+ numOfTrue;


     
        // this.filestoBase64(filesArr).then((result)=>{
        //     var temp=this.state.selectedFiles.concat(result)
        //     this.setState({selectedFiles:temp})
        // }).then((res)=>{this.createData();}) ;


    
      


    //   onChangesHandler = (e) => {
    //     var filesArr=this.state.rows
    //     console.log(filesArr)
    //     var xx= FileNameOnly(filesArr[0].FileName)
    //     console.log(xx)
    //     var newArray = this.state.myUploadedFileList
    //      var numOfTrue = (newArray.filter(x => x === xx).length)+=1;
    //      console.log(numOfTrue)

    //     var objIndex = newArray.findIndex((obj => obj.fileKey=== xx));
    // //    newArray[objIndex].fileKey= "xx"+ numOfTrue
    // //    var vv= filesArr.find(v => v.FileName === xx).name = "xx"+ numOfTrue;


     
  
    
    //   }

      //converts file to base64 so we can store/send it (for some reason , direct storing e.target.files = null) 
      filestoBase64_1=async (files)=>
      {
        let promises = [];
        function getBase64(file) {
            const reader = new FileReader();
            let fileExt = determineFileExt(file.name);
            return new Promise((resolve,reject) => {
              reader.onload = event => {
                resolve({
                  base64String:event.target.result,
                  fileName : file.name,
                  fileExt : fileExt
                })
              }
              reader.readAsDataURL(file)
            })
          }
          files.map(file=>{
            promises.push(getBase64(file))
          });
          return await Promise.all(promises)
      }


      //NEW base64 conversion. reads file, converts to base64 string, then stores in a buffer int8 array to be sent to s3
      filestoBase64=async (files)=>
      {
        let promises = [];
        function getBase64(file) {
    
            const reader = new FileReader();
            let fileExt = determineFileExt(file.name);
   
            return new Promise((resolve,reject) => {
              reader.onload = event => {
                let data = reader.result;
                let buffer = new Int8Array(data); //we need to store this in a buffer, else AWS receives and stores as a base64 array object
                resolve({
                    buffer: buffer,
                    fileName : file.name,
                    fileExt : fileExt,
                    file
                })
              }
              reader.readAsArrayBuffer(file)
            })
        }
  
          


          files.map(file=>{
            promises.push(getBase64(file))
          });
          return await Promise.all(promises)
      }

    // "cleans" the data into a format that can be fed into the dynamic table  //also removes array data when removeFile is called
//     createData=() =>{
//         var rowsarr=[]
//         var arr = [];
//         if(this.state.selectedFiles!=null)
//         var myArray = this.state.myUploadedFileList;
//         const newArray =myArray.map(element=>element.fileKey)
   
      
//         {
//             this.state.selectedFiles.map((file,index) => {
//                 if((file.fileName != null) && (file.fileExt ==="pdf") // must have file name and in pdf format
//                  &&(arr.indexOf(file.fileName) === -1) //check duplicate pdf at file selection
//                  ){


//                    if(newArray.includes(file.fileName)){ 
  
//                     let ID =index+1;
//                     let Category=file.fileExt;
//                     let filenamez = file.fileName.split('.pdf')
//                     let filenamexx= filenamez[0] + '_'+ ID +'.'+Category
               
//                     let yy=filenamexx.toString()
//                     // console.log(yy)
//                     // console.log(typeof yy);
//                     let FileName  = yy;
//                     rowsarr.push({ID,FileName,Category})  
//                     // console.log(rowsarr)
//                     this.setState({openDialog:true}
                
//                 )
            
//                 // this.filestoBase64(filesArr).then((result)=>{
//                 //     var temp=this.state.selectedFiles.concat(result)
//                 //     this.setState({selectedFiles:temp})
//                 // });
//             }

// else{
//     arr.push(file.fileName)
//     let ID =index+1;
//     console.log(ID)
//     let FileName  = file.fileName;
//     let Category=file.fileExt;
//     rowsarr.push({ID,FileName,Category})  
// }

//                 //   { this.props.sendAlert("Possible duplicate file(s) already uploaded, Please check and rename to ensure correct AWS Textract results","ErrorAlert",2000);
//                 //  }

//                  }

//                 else {
//                     var remove =index
//                     var tempArr=this.state.selectedFiles.slice();
//                     tempArr.splice(remove,1)
//                     this.setState({selectedFiles:tempArr})}
//             })
//         }
        
//         this.setState({rows:rowsarr})
//     }




    // newpdfintexttract=() =>{
    //     var rowsarr=[]
    //     var arr = [];
    //     if(this.state.selectedFiles!=null)
    //   console.log(this.state.selectedFiles)
    //     var myArray = this.state.myUploadedFileList;
    //     const newArray =myArray.map(element=>element.fileKey)
    //     console.log(newArray)

        
      
    //     {
    //         this.state.selectedFiles.map((file,index) => {
    //             if((file.fileName != null) && (file.fileExt ==="pdf") // must have file name and in pdf format
    //              &&(arr.indexOf(file.fileName) === -1) //check duplicate pdf at file selection
    //              ){
    //                 var numOfTrue = (newArray.filter(x => x === file.fileName).length)+=1;
    //                 console.log(numOfTrue)
    //                 arr.push(file.fileName)
    //                 console.log(file.fileName)
    //                 let ID =index+1;
    //                 let FileName  = file.fileName + "_" +numOfTrue
    //                 let Category=file.fileExt;
    //                 rowsarr.push({ID,FileName,Category})  
                 
    //                if(newArray.includes(file.fileName)){this.setState({openDialog:false})}
    //             //   { this.props.sendAlert("Possible duplicate file(s) already uploaded, Please check and rename to ensure correct AWS Textract results","ErrorAlert",2000);
    //             //  }

    //              }

    //             else {
    //                 var remove =index
    //                 var tempArr=this.state.selectedFiles.slice();
    //                 tempArr.splice(remove,1)
    //                 this.setState({selectedFiles:tempArr})}
    //         })
    //     }
    //     console.log(rowsarr)
    //     this.setState({rows:rowsarr})
    // }


        //handles setting of new page (pagination)
        handleChangePage = (e,newPage) =>this.setState({page:newPage});

        //handles setting of new numver of rows per page (pagination)
        handleChangeRowsPerPage = event => this.setState({rowsPerPage:+event.target.value ,page:0});


    render()
    {
        const {classes} = this.props;
        const steps=this.getSteps();
        return(
            <div className={classes.root}>
       <br/>
                <h2 style={{paddingLeft:'1rem'}}> Upload File </h2>
                <Grid container spacing={3}>
                <Grid item xs={9} md={9} lg={9} xl={9}>
     <Tabs style={{paddingLeft:'1rem'}} 
                    value={this.state.activeTab}
                    onChange={this.tabChange}
                    TabIndicatorProps={{style: {backgroundColor: '#fbca33'}}}
                >
                    <Tab className= // Upload new file tab
                    {this.state.activeTab===0 ? classes.active_tabStyle :classes.default_tabStyle}label= "Upload new file" >
                    </Tab>
                
                    <Tab disabled={!this.props.fileListLoaded}className=    // View upload tab
                    {this.state.activeTab===1 ? classes.active_tabStyle :classes.default_tabStyle}label= "My past uploads" >
                    </Tab>
                </Tabs>
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3} >
                <Alert style={{width:"30rem", height: "5rem",fontWeight:'bold'}} severity="warning">
                  <div style={{color:"red"}}>  Only files that have not yet been ingested can be deleted <br/> </div>
                    Current UTC date and time: {new Date().toLocaleString('en-US', { timeZone: 'UTC', timeZoneName: 'short' })} <br/>
                    Deletion is available until: {new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() + 1, 1, 59)).toLocaleString('en-US', { timeZone: 'UTC' })} UTC  <br/>


</Alert>
                </Grid>
                </Grid>
               
        
                { // Upload Tab Content 
                    this.state.activeTab ===0 && 
                    <TabContainer>
                         {this.uploadStepper(classes,steps)}
                    </TabContainer>
                }
                { // Upload Tab Content 
                    this.state.activeTab ===1 && 
                    <TabContainer>

                          <MyUploads groupList={this.props.groupList} contactList={this.props.contactList}  sendAlert ={this.props.sendAlert} fileList={this.props.fileList} setTab={this.props.setTab}
                          user={this.props.user.username} triggerUpdate={this.props.triggerUpdate.bind(this)} setFullRecipientList={this.setFullRecipientList.bind(this)} />
                    </TabContainer>
                    
                }
            {  this.state.openDialog && <Dialog
                    disableBackdropClick="true"
                    open={this.state.openDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Confirm File Selection"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText style={{color:"red"}} id="alert-dialog-description">
                     Warning! Possible duplicate file(s) have already been uploaded.<br/><br/>
                    Please check that the file(s) you are uploading was not recently uploaded (e.g. within the past 1 hour). <br/><br/>
                    Click on "Confirm" to continue uploading the file(s) you have chosen. <br/><br/>

                 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      { <Button onClick={()=>{this.RemoveFile(this.state.selectedFiles.length);this.setState({openDialog:false})}}>Cancel</Button> }
                      <Button contained ={true} color ="primary" onClick={this.handleClose} autoFocus>

                       i Acknowledge
                      </Button>
                      {/* <Button contained ={true} color ="secondary" onClick={this.onChangesHandler} autoFocus>

New File
</Button> */}
                    </DialogActions>
                  </Dialog>
                }
               
            </div>
        )
    }

    tabChange=(event, value, index)=>
    {
        this.refreshPage()
        this.setState({ activeTab:value });
    }

    //checks if there is a recipient and a file to send, returns inverse value as it is used in "disabled" field
    //always use .length when checking against array to avoid wrong return booleans
    isDisableUploadButton=()=>
    {
        if(this.state.recipients.length>0 && this.state.rows.length >0) return false;
        else return true;
    }
    
    //remove file from list (both selectedFiles, and calls createData(due to state update) to remove value from "rows" as well)
    RemoveFile(ID)
    {
        console.log(ID)
        var remove =ID-1
        var tempArr=this.state.selectedFiles.slice();
        tempArr.splice(remove,1)
        this.setState({selectedFiles:tempArr})
    }

    //callback to be passed to "FriendsList.js" to update recipients info
    setRecipients(rec) { 
        // var temp=this.state.selectedFiles.slice()
        // temp[fileID].recipient =rec;
        this.setState({recipients:rec}) 
    } 
    //callback to be passed to "FriendsList.js" to update full, trimmed recipients list. Should probably refactor this at some point
    setFullRecipientList(rec) { 
        this.setState({fullRecipientList:rec}) 
    } 

    //strip the group info for individual recipients
    stripGroupUsers()
    {
        var finalRecipients=[]
        var groupList=[]
        if(this.props.groupList.length>0){
        this.props.groupList[0].usergroup.map((groupuser) => {
            var user={};
            user.recipientusername=groupuser.username;
            user.ssogroupid=groupuser.groupid;
            user.ssogroupname=groupuser.organizationname;
            finalRecipients.push(user);

            // var groupIndex=this.props.groupList.findIndex(x=> x.groupname===rec)
            // if(groupIndex>-1) groupList.push(this.props.groupList[groupIndex])  //should refactor this to not copy in future
            // else {
            //     var obj ={"recipientusername":rec}
            //     finalRecipients.push(obj)   
            // } 
        })
        this.setState({finalRecipients:finalRecipients})
    }
    }


     //#region table
    //define columns of table
    columns=[
        {id:'ID',label:'#',align:'left',minWidth:'1rem',fontSize:'1rem'},/* incrementing number of item in list */
        {id:'FileName',label:'File Name',align:'left',minWidth:'10rem',fontSize:'1rem'},/* file name */
    ]

    //handles the generation of the table data from the columns defined above , returns as html for render component
    generateTable()
    {
        return(
            <ThemeProvider theme="airbus">
            <CssBaseline />
            <Paper style={{width:'100%'}}>
            
                <TableContainer style={{maxHeight: 800}}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {this.columns.map(column => (
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth ,fontSize:column.fontSize}}
                                        >
                                        <b>{column.label}</b>
                                        </TableCell>
                                    ))}
                                    <TableCell key="deleteIcon" align="left" style={{ minWidth: "2rem" ,fontSize:"1rem"}}>   <b>Delete </b> </TableCell>
                                    {/* <TableCell key="refreshIcon" align="right" style={{ minWidth: "2rem" ,fontSize:"2rem"}}  >  
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {this.state.selectedFiles.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((file,idx) => {
                                    return {ID:idx+1,FileName:file.fileName,Category:file.fileExt}
                                }).map(row => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
                                        {this.columns.map(column => {
                                            // if(column.id==="Recipient") return;
                                            // console.log(column.id)
                                            const value = row[column.id];
                                            return (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: "8rem" ,fontSize:"1rem"}}>
                                                {value}
                                            </TableCell>
                                            );
                                        })}
                                            <TableCell key="delete" align="left" style={{ minWidth: "2rem" ,fontSize:"1rem"}}>
                                            <IconButton contained ={true} color="primary"
                                                // onClick={()=>{this.RemoveFile(this.state.rows[row.ID].FileName)}}> 
                                                onClick={()=>{this.RemoveFile(row.ID)}}> 
                                                 <Delete />
                                                 </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            
                        </TableBody>
                    </Table>
                </TableContainer>    
                { <TablePagination 
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={this.state.rows.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                /> }
            </Paper>
            </ThemeProvider>
        )
    }

    //#endregion

    //#region Stepper
    uploadStepper(classes ,steps)
    {
        return(
            <div>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
            <Step key={steps[0]} /*Division for selecting files*/>
                <StepLabel error={this.state.showNonPdfError}>{steps[0]}</StepLabel>
                <StepContent TransitionComponent="None">
                    {this.generateTable()}
                    <Button contained ={true} color ="secondary" for="input" type="submit" onClick={()=>{document.getElementById("input").click()}} style={{fontWeight: 'bold'}} 
                    disabled={(this.state.activeStep!==0) || !this.props.fileListLoaded}>
                        Choose Files
                        </Button>
                    <input type="file" class="form-control" id="input"  accept="application/pdf" hidden multiple onChange={this.onChangeHandler} />
                        <div className={classes.actionsContainer}>
                            <div>
       
                            <Button
                                disabled={!this.state.selectedFiles.length >0||(this.state.activeStep!==0)}
                                variant="contained"
                                color="primary"
                                onClick={()=>this.handleStep("handleNext")}
                                className={classes.button}
                            >   Next
                                {/* {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next' /* this was for dynamic looping of content, we can remove this and replace with just the required text actually */} 
                            </Button>
                            </div>
                        </div>
                </StepContent>
            </Step>
            
            

            <Step key={steps[1]} /*Division for selecting upload*/>
                <StepLabel>{steps[1]}</StepLabel>
                <StepContent>

                {this.state.loaded>0 &&
                <Progress max="100" color="info" value={this.state.loaded>0? Math.round(this.state.loaded):0}
                className="text-center"
                >{this.state.loaded>0?Math.round(this.state.loaded):0}%</Progress>
                }
                
                <Button contained ={true} color= "primary" type="submit" onClick={this.handleSubmit} style={{ fontWeight: 'bold', marginLeft: '1rem'}}  disabled={this.state.cycle} >Upload</Button>

                        <div className={classes.actionsContainer}>
                            <div>
                            <Button
                                disabled={this.state.activeStep === 0 || this.state.cycle}
                                onClick={()=>this.handleStep("handleBack")}
                                className={classes.button}
                            >
                                Back
                            </Button>
                            </div>
                        </div>
                </StepContent>
            </Step>
           
        </Stepper>
        {/* {console.log(steps.length)} */}
        {this.state.activeStep >= steps.length && ( 
            <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={()=>this.handleStep("handleReset")} className={classes.button} color="primary"  disabled={this.state.streaming}>
                Upload more files
            </Button>
            </Paper>
        )}
        </div>
        )
    }

    //legacy code from original looping stepper method.Required unless step reference is removed in stepper above
    getSteps() {
        return [this.state.showNonPdfError?'Please Select PDF Files Only':'Select files to upload', 'Upload files'];
    }

    //increments,decrements or resets current step as required
    handleStep=(type)=>
    {
        var stateVal;
        // console.log(type)
        switch(type)
        {
            case "handleNext": 
            stateVal=this.state.activeStep;
            stateVal++;
            this.setState({activeStep:stateVal});
            return;

            case "handleBack": 
            stateVal=this.state.activeStep;
            stateVal--;
            this.setState({activeStep:stateVal});
            return;

            case "handleReset": 
            this.setState({activeStep:0});
            this.refreshPage();
            return;

            default:return;
        }
    }

    //#endregion


}
export default withStyles(styles)(UploadFiles); 
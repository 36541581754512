import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
//import Admin from "views/Admin.js"
import User from "./views/User.js"
import Admin from "./views/Admin.js"
import Login from "./views/LoginPage.js"
import Auth from "./views/Authentication.js"
import SuperAdmin from "./views/SuperAdmin.js"
import Disclaimer from "./views/Disclaimer/Disclaimer.js"
import PersonalData from "./views/Disclaimer/PersonalData.js"

const hist = createBrowserHistory(); //This is so we can use "back" for client routing


ReactDOM.render(
    <Router history={hist}>
      <Switch>
        {/* <Route path="/admin" component={Admin}/> */}
        <Route  path="/sso"  component={() => { 
          // window.location.href = process.env.REACT_APP_SSO+'/login?success_callbackurl='+window.location.origin+'/auth&failure_callbackurl='+window.location.origin+'/login&applicationname=FileSharing';
          window.location.href = process.env.REACT_APP_SSO+'/login?success_callbackurl='+window.location.origin+'/auth&applicationname=FileSharing';

          return null;
          }} /> 
        <Route path="/login" component={Login}/>
        <Route path="/disclaimer" component={Disclaimer}/>
        <Route path="/personaldata" component={PersonalData}/>
        <Route path="/user" component={User} />
        <Route path="/admin" component={Admin} />
        <Route path="/superadmin" component={SuperAdmin} />
        <Route path="/auth" component={Auth}/>
        <Redirect from="/" to="/login"  />
 
      </Switch>
    </Router>,
    document.getElementById("root")
  );
import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import {Paper,Container} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import Documentation from './Documentation.pdf'
import { sizing } from '@material-ui/system';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { IconButton} from "horizon-components-react";

import { pdfjs } from 'react-pdf';//to fix an issue with the different methods of creating react apps
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //to fix an issue with the different methods of creating react apps



const styles = theme => ({
  root: {
    //padding: theme.spacing(3, 2),
    //height: 10000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper:
  {
    display: "inline-block",
    // minWidth: 500,
    // minHeight: 275,
    maxHeight:window.innerHeight
  },
  page:
  {
    display: "inline-block",
    maxHeight:window.innerHeight
  },
  });

class RenderPDF extends Component {
  state = { 
    numPages: null, pageNumber: 1 ,
    scale:1.0,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  // goToPrevPage = () =>
  //   this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  // goToNextPage = () =>
  //   this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  render() {
    const { pageNumber, numPages } = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.root}>

        <Container fixed>
         <Paper   className={classes.paper} ref='UniqueElementIdentifier'> { this.renderPDf() }</Paper>
        </Container>
      </div>
    );
  }

  goToPrevPage = () =>
  {
    if(this.state.pageNumber>1) this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  }
    
  goToNextPage = () =>
  {
    if(this.state.pageNumber<this.state.numPages)this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
  }

  scaleDown=()=> this.setState(state => ({ scale: state.scale - 0.1 }));
  scaleUp=()=> this.setState(state => ({ scale: state.scale + 0.1 }));

  ///display pdf
  renderPDf=()=>
  {
    const { pageNumber, numPages,scale } = this.state;
    const {classes} = this.props;
    return(
      <div >
        <nav >
          <IconButton onClick={this.goToPrevPage} color ="blue">
          <ArrowBackIosIcon/>
          </IconButton>
          Page {pageNumber} of {numPages}
          <IconButton onClick={this.goToNextPage} color ="blue">
            <ArrowForwardIosIcon/>
          </IconButton>
        </nav>
        <div style={{ width: 900 }}>
        <Document
          file={Documentation}
          onLoadSuccess={this.onDocumentLoadSuccess}
          maxHeight={window.innerHeight} 
        >
          <Page className={classes.page}pageNumber={pageNumber} width={600} maxHeight={window.innerHeight} scale={this.state.scale}/>
        </Document>
      </div>
      <nav >
          <IconButton onClick={this.scaleDown} color ="blue">
          <RemoveIcon/>
          </IconButton>
          Scale: {scale.toFixed(1)} %
          <IconButton onClick={this.scaleUp} color ="blue">
            <AddIcon/>
          </IconButton>
        </nav>

      {/* <p>
        Page {pageNumber} of {numPages}
      </p> */}
      </div>
    )
  }
}
export default withStyles(styles)(RenderPDF); 